/* eslint-disable no-nested-ternary */
import { ChevronDownIcon, ChevronUpIcon, MinusIcon } from "@heroicons/react/outline";
import React, { Dispatch, SetStateAction, useState } from "react";
import { SortParams } from "../../../../@types/SortParams";

interface Props {
  name: string,
  sortName: string,
  activeSort: string,
  activeSortDirection: boolean,
  changeSortCallback: Dispatch<SetStateAction<SortParams>>
}

const FileTableHeader = (props: Props): JSX.Element => {
    const [isDescending, setSort] = useState(props.activeSortDirection);

    const clickSort = () => {
        const val = !isDescending;
        setSort(val);

        props.changeSortCallback({
            sortColumn: props.sortName,
            isDescending: val,
        });
    };

    return (
        <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider cursor-pointer"
            onClick={clickSort}
        >
            <span className="align-middle">{props.name}</span>
            {
                props.activeSort !== props.sortName
                    ? <MinusIcon className="inline-block text-gray-400 align-middle w-6 h-6" />
                    : isDescending
                        ? <ChevronUpIcon className="inline-block text-gray-400 align-middle w-6 h-6" />
                        : <ChevronDownIcon className="inline-block text-gray-400 align-middle w-6 h-6" />
            }
        </th>
    );
};

export { FileTableHeader };

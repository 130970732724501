import React, { useState } from "react";

import PlaybookImg from "../../Assets/Images/primer_cloud_playbook.jpg";
import { useAPI } from "../../Hooks/useAPI";
import { PlaybooksMap } from "../../appConstants";
import { PageHeader } from "../../Components/Core/PageHeader";

interface UnitResult {
    successful: boolean
    errors: string[]
}

const PlaybookPrimerPage = (): JSX.Element => {
    const { download } = useAPI();
    const [downloading, setDownloading] = useState(false);

    const downloadPlaybook = () => {
        setDownloading(true);

        download<UnitResult>(`playbook-primer/download?playbookId=${PlaybooksMap.Cloud}`).then(error => {
            if (error && !error.successful) {
                // Display an error - toast?
            }

            setDownloading(false);
        });
    };

    return (
        <>
            <PageHeader header="Playbook primer" />
            <div className="w-full mt-3 p-4 md:p-8 overflow-hidden space-y-6 bg-white border shadow-sm md:rounded-md md:w-auto">
                <div className="w-full space-y-3 lg:space-y-5">
                    <p className="text-lg">
                        The Cloud Playbook primer is ready for you to download using the button below.
                    </p>
                    <p>Within this Playbook you’ll find:</p>
                    <ol className="list-decimal ml-5 space-y-2">
                        <li>
                            An introduction, identifying the business outcomes a transformation programme will help you achieve.
                        </li>
                        <li>
                            The Strategy component – used to confirm your business vision and how the technical transformation will enable your objectives.
                        </li>
                        <li>
                            The Foundation component – transitioning from strategy into programme setup.
                        </li>
                        <li>
                            The Execution component – delivery of your end-to-end cloud migrations.
                        </li>
                    </ol>
                    <p>
                        Consultants offer complexity. Our Plug and Playbooks<span className="text-superscript">®</span> offer simplicity.
                    </p>
                </div>
                <div className="flex flex-col items-center">
                    <img src={PlaybookImg} alt="" className="max-h-40 rounded-lg" />
                    <div className="flex items-center">
                        <button
                            type="button"
                            disabled={downloading}
                            onClick={downloadPlaybook}
                            className="-mt-6 disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent border-t-2 border-t-white border-l-2 border-l-white text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                        >
                            Download Primer
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export { PlaybookPrimerPage };

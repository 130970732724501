import React, { useState } from "react";
import { LoadingSpinner } from "../../../../Components/Core/LoadingSpinner";
import { InviteUserModal } from "../InviteUserModal";
import { useAPI } from "../../../../Hooks/useAPI";
import { classNames } from "../../../../Utils/Helpers";
import { UserInvite } from "../../../../@types/UserInvite";
import { UnitResult } from "../../../../@types/Result";

interface Props {
    companyId: number,
    className?: string,
    completeCallback: () => void,
}

const SendInvite = (props: Props) => {
    const { post } = useAPI();
    // const bus = useBus();
    const [loading, setLoading] = useState(false);
    const [waitingOnConfirmation, setWaitingOnConfirmation] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const onSendInvite = () => {
        setErrors([]);
        setLoading(true);
        setWaitingOnConfirmation(true);
    };

    const sendInvite = (userInvite: UserInvite) => {
        post<UnitResult>("user/invite/invite-user", {
            companyId: props.companyId,
            forename: userInvite.forename,
            surname: userInvite.surname,
            email: userInvite.emailAddress,
            jobRole: userInvite.jobRole,
            department: userInvite.department,
            allowedDocumentFolderIds: userInvite.allowedDocumentFolderIds,
            isCompanyAdministrator: userInvite.isCompanyAdministrator,
        })
            .then((result) => {
                setLoading(false);

                if (result.successful) {
                    setWaitingOnConfirmation(false);
                } else {
                    setErrors(result.errors);
                }
            })
            .catch(() => {
                setLoading(false);
            })
            .finally(() => {
                props.completeCallback();
            });
    };

    const inviteConfirmed = (userInvite: UserInvite) => {
        sendInvite(userInvite);
    };

    const inviteCancelled = () => {
        setLoading(false);
        setWaitingOnConfirmation(false);
    };

    return (
        <button
            type="button"
            className={classNames(props.className ?? "")}
            onClick={onSendInvite}
        >
            {loading
                ? <LoadingSpinner className="inline-block w-6 h-6 m-2" />
                : <span>Send Invite</span>}
            <InviteUserModal
                open={waitingOnConfirmation}
                errors={errors}
                confirmCallback={inviteConfirmed}
                cancelCallback={inviteCancelled}
            />
        </button>
    );
};

export { SendInvite };

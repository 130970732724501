import React, { useState, useEffect } from "react";
import { ArrowRightIcon, ChevronRightIcon, InformationCircleIcon } from "@heroicons/react/solid";
import { classNames } from "../../Utils/Helpers";
import { Link, useHistory } from "react-router-dom";
import { useAPI } from "../../Hooks/useAPI";
import { Result } from "../../@types/Result";
import { toast } from "react-toastify";
import { DocumentFolder } from "../../@types/DocumentFolder";
import { PageHeader } from "../../Components/Core/PageHeader";

const HomePage = (): JSX.Element => {
    const history = useHistory();
    const { get } = useAPI();
    const [documentFolders, setDocumentFolders] = useState<DocumentFolder[] | null>(null);
    const [selectedChildFolder, setSelectedChildFolder] = useState<DocumentFolder | null>(null);
    const [selectedDocumentFolder, setSelectedDocumentFolder] = useState<DocumentFolder | null>(null);

    const setSelectedTopLevelFolder = (folder: DocumentFolder) => {
        setSelectedChildFolder(null);
        setSelectedDocumentFolder(folder);
    };

    useEffect(() => {
        get<Result<any>>("home/document-folders").then(r => {
            if (r.successful) {
                setDocumentFolders(r.data);
            } else {
                toast.error("Something went wrong.");
                setTimeout(() => {
                    history.replace("/");
                }, 3000);
            }
        });
    }, []);

    const parseImage = (uri: string | undefined) => (uri ? uri.replace("tw://", "") : "");

    return (
        <>
            <PageHeader header="Home" />
            <div className="bg-white mt-3 px-3 py-5 md:rounded-t-lg border shadow border-gray-200">
                <InformationCircleIcon className="w-7 h-7 inline text-primary-700 mr-2" />
                <p className="inline align-middle">
                    Choose a section of the playbook to begin exploring
                </p>
            </div>
            <div className="grid grid-cols-2 md:rounded-b-lg bg-white border overflow-hidden shadow border-t-0 mb-4 border-gray-200 divide-y divide-gray-200 xl:grid-cols-4 md:divide-y-0 md:divide-x">
                {documentFolders && documentFolders.filter(x => x.parentFolderId === null).map((item) => (
                    <div
                        role="none"
                        key={item.id}
                        className={`flex px-3 py-3 cursor-pointer relative px-4 py-5 text-white bg-gray-800 bg-center bg-cover h-60 sm:px-6 ${parseImage(item.coverImage)}`}
                        onClick={() => setSelectedTopLevelFolder(item)}
                    >
                        <div className={classNames(selectedDocumentFolder?.name === item.name
                            ? "from-primary-500"
                            : "from-primary-700 via-transparent",
                        "absolute inset-0 flex items-end w-full h-full px-6 py-4 bg-gradient-to-t to-transparent")}
                        >
                            <span className="text-xl shadow-sm">{item.name}</span>
                        </div>
                    </div>
                ))}
            </div>
            {selectedDocumentFolder && selectedDocumentFolder.permitted === true && (
                <div className="rounded-md border shadow bg-white p-4 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-primary-500" aria-hidden="true" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-primary-700">{selectedDocumentFolder.description}</p>
                            <p className="mt-3 text-sm md:mt-0 md:ml-6">
                                <Link to={`aitem-files?folderId=${selectedDocumentFolder.id}`} className="flex whitespace-nowrap font-medium text-primary-700 hover:text-primary-600">
                                    {selectedDocumentFolder.name}
                                    <ArrowRightIcon className="flex ml-1 h-5 w-5" />
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {selectedDocumentFolder && selectedDocumentFolder.permitted === false && (
                <div className="rounded-md border shadow bg-white p-4 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-secondary-500" aria-hidden="true" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-primary-700">You do not have permission to access this topic, please contact your Company Administrator.</p>
                        </div>
                    </div>
                </div>
            )}
            {selectedDocumentFolder && selectedDocumentFolder.permitted && (
                <div className="lg:grid lg:grid-cols-3 rounded-md border shadow bg-white p-3">
                    <div className="p-3 m-2">
                        <div className="m-1 mb-3 px-1">
                            <h2 className="text-lg font-bold">Section Contents</h2>
                        </div>
                        {documentFolders?.filter(x => x.parentFolderId === selectedDocumentFolder?.id).map(folder => (
                            <div
                                role="none"
                                className={`mx-1 my-2 px-3 py-2 border rounded-lg border-gray-300 hover:bg-gray-50 cursor-pointer ${selectedChildFolder?.id === folder.id ? "border-secondary-500 bg-secondary-50 hover:bg-secondary-100" : ""}`}
                                key={folder.id}
                                onClick={() => setSelectedChildFolder(folder)}
                            >
                                <span className="mr-2">
                                    {folder.name}
                                </span>
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                    {documentFolders.filter(x => x.parentFolderId === folder.id).length}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="lg:col-span-2 p-3 m-2 mt-9">
                        {documentFolders?.filter(x => x.parentFolderId === selectedChildFolder?.id).map(child => (
                            <Link to={`/aitem-files?folderId=${child.id}`} className="block mt-3 mb-4">
                                <ChevronRightIcon className="w-5 h-5 inline" />
                                <span className="hover:underline">
                                    {child.name}
                                </span>
                            </Link>
                        ))}
                        {selectedChildFolder && documentFolders?.filter(x => x.parentFolderId === selectedChildFolder?.id).length === 0 && (
                            <>
                                <p className="mb-2">
                                    There aren&apos;t any more folders within {selectedChildFolder.name}.
                                </p>
                                <Link className="underline" to={`aitem-files?folderId=${selectedChildFolder.id}`}>
                                    Click here to see the Aitems&reg;.
                                </Link>
                            </>
                        )}
                    </div>
                    {documentFolders?.filter(x => x.parentFolderId === selectedDocumentFolder?.id).length === 0 && (
                        <div className="px-3 pt-2 mx-3 mb-3">
                            <p className="mb-2">
                                There aren&apos;t any more folders within {selectedDocumentFolder.name}.
                            </p>
                            <Link className="underline" to={`aitem-files?folderId=${selectedDocumentFolder.id}`}>
                                Click here to see the Aitems&reg;.
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export { HomePage };

/* eslint-disable max-len */
import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { UserAddIcon } from "@heroicons/react/outline";
import { UserInvite } from "../../../../@types/UserInvite";

interface Props {
    open: boolean,
    errors: string[],
    confirmCallback: (userInvite: UserInvite) => void,
    cancelCallback: () => void
}

const AvailableDocumentFolders = [
    { Id: 1, Name: "Introduction" },
    { Id: 2, Name: "Strategy" },
    { Id: 3, Name: "Foundation" },
    { Id: 4, Name: "Execution" },
];

const InviteUserModal = (props: Props): JSX.Element => {
    const [open, setOpen] = useState(false);
    const cancelButtonRef = useRef(null);
    const [userInvite, setUserInvite] = useState<UserInvite>({
        forename: "",
        surname: "",
        emailAddress: "",
        jobRole: "",
        department: "",
        allowedDocumentFolderIds: [],
        isCompanyAdministrator: false,
    });

    useEffect(() => {
        setOpen(props.open);
    }, [props]);

    const confirm = () => {
        setOpen(false);
        props.confirmCallback(userInvite);
    };

    const cancel = () => {
        setOpen(false);
        props.cancelCallback();
    };

    const toggleDocumentFolder = (id: number, checked: boolean) => {
        const index = userInvite.allowedDocumentFolderIds.indexOf(id);
        const newValues = userInvite.allowedDocumentFolderIds;

        if (index >= 0 && checked === false) {
            newValues.splice(index, 1);
        } else if (index === -1 && checked === true) {
            newValues.push(id);
        }

        setUserInvite(current => ({
            ...current,
            allowedDocumentFolderIds: newValues,
        }));
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed inset-0 z-10 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={cancel}
            >
                <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                        >
                            <div>
                                <div
                                    className="flex items-center justify-center w-12 h-12 mx-auto rounded-full bg-secondary-100"
                                >
                                    <UserAddIcon className="w-6 h-6 text-secondary-700" aria-hidden="true" />
                                </div>
                                <div className="mt-3 space-y-5 sm:mt-5">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-center text-gray-900">
                                        Invite user
                                    </Dialog.Title>
                                    {props.errors && props.errors.map(error => (<p className="w-full text-center text-red-600">{error}</p>))}
                                    <div className="-space-y-px rounded-md shadow-sm isolate">
                                        <div
                                            className="relative px-3 py-2 border border-gray-300 rounded-md rounded-b-none focus-within:z-10 focus-within:ring-1 focus-within:ring-primary-600 focus-within:border-primary-600"
                                        >
                                            <label
                                                htmlFor="forename"
                                                className="block text-xs font-medium text-gray-700"
                                            >
                                                Forename
                                            </label>
                                            <input
                                                type="text"
                                                name="forename"
                                                id="forename"
                                                className="block w-full p-0 text-gray-900 placeholder-gray-500 border-0 focus:ring-0 sm:text-sm"
                                                placeholder=""
                                                onChange={(e) => setUserInvite((s) => ({
                                                    ...s,
                                                    forename: e.target.value,
                                                }))}
                                            />
                                        </div>
                                        <div
                                            className="relative px-3 py-2 border border-gray-300 focus-within:z-10 focus-within:ring-1 focus-within:ring-primary-600 focus-within:border-primary-600"
                                        >
                                            <label
                                                htmlFor="surname"
                                                className="block w-full text-xs font-medium text-gray-700"
                                            >
                                                Surname
                                            </label>
                                            <input
                                                type="text"
                                                name="surname"
                                                id="surname"
                                                className="block w-full p-0 text-gray-900 placeholder-gray-500 border-0 focus:ring-0 sm:text-sm"
                                                onChange={(e) => setUserInvite((s) => ({
                                                    ...s,
                                                    surname: e.target.value,
                                                }))}
                                            />
                                        </div>
                                        <div
                                            className="relative px-3 py-2 border border-gray-300 focus-within:z-10 focus-within:ring-1 focus-within:ring-primary-600 focus-within:border-primary-600"
                                        >
                                            <label
                                                htmlFor="email-address"
                                                className="block w-full text-xs font-medium text-gray-700"
                                            >
                                                Email address
                                            </label>
                                            <input
                                                type="text"
                                                name="email-address"
                                                id="email-address"
                                                className="block w-full p-0 text-gray-900 placeholder-gray-500 border-0 focus:ring-0 sm:text-sm"
                                                onChange={(e) => setUserInvite((s) => ({
                                                    ...s,
                                                    emailAddress: e.target.value,
                                                }))}
                                            />
                                        </div>
                                        <div
                                            className="relative px-3 py-2 border border-gray-300 focus-within:z-10 focus-within:ring-1 focus-within:ring-primary-600 focus-within:border-primary-600"
                                        >
                                            <label
                                                htmlFor="job-role"
                                                className="block w-full text-xs font-medium text-gray-700"
                                            >
                                                Job role
                                            </label>
                                            <input
                                                type="text"
                                                name="job-role"
                                                id="job-role"
                                                className="block w-full p-0 text-gray-900 placeholder-gray-500 border-0 focus:ring-0 sm:text-sm"
                                                onChange={(e) => setUserInvite((s) => ({
                                                    ...s,
                                                    jobRole: e.target.value,
                                                }))}
                                            />
                                        </div>
                                        <div
                                            className="relative px-3 py-2 border border-gray-300 focus-within:z-10 focus-within:ring-1 focus-within:ring-primary-600 focus-within:border-primary-600"
                                        >
                                            <label
                                                htmlFor="department"
                                                className="block w-full text-xs font-medium text-gray-700"
                                            >
                                                Department
                                            </label>
                                            <input
                                                type="text"
                                                name="department"
                                                id="department"
                                                className="block w-full p-0 text-gray-900 placeholder-gray-500 border-0 focus:ring-0 sm:text-sm"
                                                onChange={(e) => setUserInvite((s) => ({
                                                    ...s,
                                                    department: e.target.value,
                                                }))}
                                            />
                                        </div>
                                        <div
                                            className="relative px-3 py-2 border border-gray-300 rounded-md rounded-t-none focus-within:z-10 focus-within:ring-1 focus-within:ring-primary-600 focus-within:border-primary-600"
                                        >
                                            <label
                                                htmlFor="department"
                                                className="block w-full text-xs font-medium text-gray-700"
                                            >
                                                What additional roles does the user have?
                                            </label>
                                            <div className="relative flex items-start px-2 my-5">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        id="isCompanyAdministrator"
                                                        name="isCompanyAdministrator"
                                                        type="checkbox"
                                                        className="w-4 h-4 border-gray-300 rounded focus:ring-primary-500 text-primary-600"
                                                        onChange={(e) => setUserInvite((s) => ({
                                                            ...s,
                                                            isCompanyAdministrator: e.target.checked,
                                                        }))}
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm text-left">
                                                    <label
                                                        htmlFor="isCompanyAdministrator"
                                                        className="font-medium text-gray-700"
                                                    >
                                                        Company administrator
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="relative px-3 py-2 border border-gray-300 rounded-md rounded-t-none focus-within:z-10 focus-within:ring-1 focus-within:ring-primary-600 focus-within:border-primary-600"
                                        >
                                            <label
                                                htmlFor="department"
                                                className="block w-full text-xs font-medium text-gray-700"
                                            >
                                                Which sections can the user access?
                                            </label>
                                            <fieldset className="px-2 mb-5 space-y-5">
                                                <legend className="sr-only">Sections</legend>
                                                {AvailableDocumentFolders.map((item) => (
                                                    <div className="relative flex items-start">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                id={`section-${item.Id}`}
                                                                key={item.Id}
                                                                name={item.Name}
                                                                type="checkbox"
                                                                className="w-4 h-4 border-gray-300 rounded text-primary-600 focus:ring-primary-500"
                                                                onChange={(e) => toggleDocumentFolder(item.Id, e.target.checked)}
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label htmlFor={item.Name} className="font-medium text-gray-700">
                                                                {item.Name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm disabled:opacity-50 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:col-start-2 sm:text-sm"
                                    onClick={confirm}
                                >
                                    Send invite
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={cancel}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export { InviteUserModal };

import { HeartIcon, DocumentTextIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useListener } from "react-bus";
import { NavLink } from "react-router-dom";
import { AitemFile } from "../../../../../../@types/File";
import { PaginatedResult } from "../../../../../../@types/Result";
import { useAPI } from "../../../../../../Hooks/useAPI";
import { LoadingSpinner } from "../../../../../Core/LoadingSpinner";
import { SideNavHeader } from "../../../SideNavHeader";
import { SideNavLink } from "../../../SideNavLink";
import { FileDownload } from "../../../../../File/FileDownload";

const Favourites = () => {
    const { post } = useAPI();
    const [loading, setLoading] = useState(false);
    const [favourites, setFavourites] = useState<AitemFile[]>([]);

    const favouritesRequest = {
        filters: {
            isFavourite: { value: true },
        },
        paginationParameters: {
            pageNumber: 1,
            orderByProperty: "FavouriteDate",
            isDescending: true,
            itemsPerPage: 5,
        },
    };

    const updateFavourites = () => {
        post<PaginatedResult<AitemFile>>("files", favouritesRequest).then(rows => {
            setFavourites(rows.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        updateFavourites();
    }, []);

    useListener("favouriteAdded", updateFavourites);
    useListener("favouriteRemoved", updateFavourites);

    return !loading ? (
        <div className="flex-1 px-2 space-y-3">
            <SideNavHeader text="Favourites" icon={HeartIcon} />
            <div className="mt-1 space-y-1" aria-labelledby="recent-downloads-headline">
                { favourites && favourites.length > 0
                    ? favourites.map((file) => (
                        <FileDownload
                            documentId={file.id}
                            fileName={file.name}
                            size={file.fileSizeBytes}
                            format={file.fileType}
                        >
                            <SideNavLink
                                key={file.id}
                                item={{
                                    name: file.name,
                                    href: `/aitem-files/${file.id}`,
                                    pageComponent: () => <></>,
                                    hideNav: true,
                                    icon: DocumentTextIcon,
                                }}
                                isDisabled
                            />
                        </FileDownload>
                    ))
                    : <span className="text-primary-200 group flex items-center px-2 ml-4 py-2 text-xs">No favourites.</span>}
                <NavLink
                    to="/aitem-files?favourites=true"
                    className="flex items-center px-3 py-2 text-sm font-medium rounded-md text-primary-100 group hover:bg-primary-600"
                >
                    See more...
                </NavLink>
            </div>
        </div>
    ) : <LoadingSpinner />;
};

export { Favourites };

import React from "react";
import { BellIcon } from "@heroicons/react/outline";

const NotificationsMenu = () => (
    <button type="button" className="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
        <span className="sr-only">View notifications</span>
        <BellIcon className="w-6 h-6" aria-hidden="true" />
    </button>
);

export { NotificationsMenu };

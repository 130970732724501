import React, { useContext } from "react";
import { AuthContext } from "../../../Context/AuthProvider";
import { LoadingSpinner } from "../../Core/LoadingSpinner";

const AuthSilentRenew = () => {
    const authContext = useContext(AuthContext);
    authContext?.signinSilentCallback();

    return (
        <div className="flex h-screen mx-auto align-middle">
            <LoadingSpinner />
        </div>
    );
};

export { AuthSilentRenew };

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useAPI } from "../../Hooks/useAPI";
import { FilterParams } from "../../@types/CompanyUser";
import { PaginatedResult } from "../../@types/Result";
import { SortParams } from "../../@types/SortParams";
import { DownloadLogTable } from "./Components/DownloadLogTable";
import { PageHeader } from "../../Components/Core/PageHeader";
import { Download } from "../../@types/Download";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const DownloadLogPage = () => {
    const query = useQuery();

    const [downloads, setDownloads] = useState<any>({});
    const [filterValues, setFilterValues] = useState<FilterParams>({ search: query.get("search") ?? undefined });

    const [sortValue, setSortValue] = useState<SortParams>({ isDescending: true, sortColumn: "dateDownloaded" });
    const [pageIndex, setPageIndexValue] = useState(parseInt(query.get("page") ?? "1", 10));
    const [loading, setLoading] = useState(true);

    const { post } = useAPI();
    const { replace } = useHistory();

    const loadData = () => {
        const paginationRequest: any = {
            filters: {
                search: { value: filterValues.search },
            },
            paginationParameters: {
                pageNumber: pageIndex,
                OrderByProperty: sortValue.sortColumn,
                IsDescending: sortValue.isDescending,
            },
        };

        post<PaginatedResult<Download>>("company-profile/download-log", paginationRequest).then(result => {
            setDownloads(result);
            setLoading(false);
        });

        const params = new URLSearchParams();

        // swap all filterValues to an iterate
        if (filterValues?.search) {
            params.append("search", filterValues.search.toString());
        }

        if (sortValue?.sortColumn) {
            params.append("sortBy", sortValue.sortColumn);
        }

        if (sortValue?.isDescending) {
            params.append("sortDesc", sortValue.isDescending ? "true" : "false");
        }

        if (pageIndex) {
            params.append("page", pageIndex.toString());
        }

        replace({ search: params.toString() });
    };

    const onSearch = (value: string) => {
        setFilterValues(curr => ({
            ...curr,
            search: value,
        }));
        setPageIndexValue(1);
    };

    const onChange = () => {
        loadData();
    };

    useEffect(loadData, [filterValues, pageIndex, sortValue]);

    return (
        <>
            <PageHeader header="Download Log" />
            <div className="space-y-8 mt-4">
                <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div className="flex justify-between px-4 py-5 sm:px-6">
                        <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Download Log</h3>
                            <p className="max-w-2xl mt-1 text-sm text-gray-500">
                                View the documents downloaded by your company&apos;s users.
                            </p>
                        </div>
                    </div>
                    <div className="border-t border-gray-200">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <DownloadLogTable
                                        loading={loading}
                                        rows={downloads}
                                        filterValues={filterValues}
                                        activeSortColumn={sortValue.sortColumn}
                                        activeSortDirection={sortValue.isDescending}
                                        navigatePageCallback={setPageIndexValue}
                                        sortCallback={setSortValue}
                                        onSearch={onSearch}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { DownloadLogPage };

import React, { useContext } from "react";

import { AuthContext } from "../../../Context/AuthProvider";
import { LoadingSpinner } from "../../Core/LoadingSpinner";

const LoginCallback = () => {
    const authContext = useContext(AuthContext);
    authContext?.signinRedirectCallback();

    return (
        <div className="flex h-screen mx-auto align-middle">
            <LoadingSpinner />
        </div>
    );
};

export { LoginCallback };

import React, { ReactChild, useMemo, useRef } from "react";
import { AuthService } from "../../Utils/AuthService";

const AuthContext = React.createContext<AuthContextData | undefined>(undefined);

interface Props {
    children: ReactChild
}

interface AuthContextData {
    signinRedirectCallback: () => (void),
    logout: () => (void),
    signoutRedirectCallback: () => (void),
    isAuthenticated: () => (boolean),
    signinRedirect: () => (void),
    signinSilentCallback: () => (void),
    createSigninRequest: () => ({})
}

const AuthProvider = ({ children }: Props) => {
    const authService = useRef<AuthService>(new AuthService());

    const authContext = useMemo(() => ({
        logout: authService.current.logout,
        signoutRedirectCallback: authService.current.signoutRedirectCallback,
        signinRedirectCallback: authService.current.signinRedirectCallback,
        isAuthenticated: authService.current.isAuthenticated,
        createSigninRequest: authService.current.createSigninRequest,
        signinSilentCallback: authService.current.signinSilentCallback,
        signinRedirect: authService.current.signinRedirect,
    }), [authService]);

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };

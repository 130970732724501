import React from "react";
import { ToastContainer } from "react-toastify";

interface Props {
  children: JSX.Element,
}

const UnauthenticatedLayout = (props: Props): JSX.Element => (
    <div className="topography">
        <div className="flex min-h-screen mx-auto 2xl:max-w-screen-2xl">
            {props.children}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                pauseOnHover
                limit={8}
            />
        </div>
    </div>);

export { UnauthenticatedLayout };

import React from "react";
import { PageHeader } from "../../Components/Core/PageHeader";

const LegalDisclaimerPage = (): JSX.Element => (
    <>
        <PageHeader header="Legal disclaimer" />
        <div className="w-full mt-4 p-4 space-y-3 overflow-hidden bg-white border shadow-sm md:p-8 lg:space-y-5 md:rounded-md md:w-auto">
            <p>Aitemology Ltd (a company registered in England and Wales under company number 13310119) owns the intellectual property rights subsisting in all materials including but not limited to: any concepts, images, photographs, designs, drawings, texts, artwork, graphics, sound recordings, video and audio materials, logos, taglines, processes, know-how, business methods, programming codes, software and data created for or developed by or on behalf of Aitemology Ltd in this Plug and Playbook<span className="text-superscript">®</span> Portal (“Portal”). The Portal is the online, distributed version of Aitemology Ltd’s Plug and Playbook™.</p>
            <p>Aitemology Ltd does not permit you to use, copy, modify and/or develop this Primer. The sole exception is the internal distribution and use of the Portal by permanent staff members within your company.</p>
            <p>You acknowledge that any and all intellectual property rights within this Primer are legally owned by Aitemology Ltd and are protected under the Copyright, Designs and Patents Act 1988.</p>
            <p>Failure to comply with any of these terms will result in all rights and permissions ceasing automatically. Please email privacy@aitemology.com for further information, discussions and/or concerns.</p>
        </div>
    </>
);

export { LegalDisclaimerPage };

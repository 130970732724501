import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { AuthContext } from "../Context/AuthProvider";
import { getToken } from "../Utils/AuthHelper";

const domainUrl = process.env.REACT_APP_API_URL_BASE;

const getHeaders = (details: any) => {
    const token = getToken();
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "",
    };

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    return ({
        headers,
        ...details,
        responseType: "json",
    });
};

const useAPI = () => {
    const authContext = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const { push } = useHistory();

    const parseResponse = (response: any) => {
        const contentType = response.headers.get("content-type");
        if (contentType === "text/plain; charset=utf-8") {
            return response.text();
        }
        return response.json();
    };

    async function common<T>(url: string, request: any): Promise<T> {
        try {
            setLoading(true);
            const response = await fetch(domainUrl + url, getHeaders(request));
            setLoading(false);

            if (!response || !response.ok) {
                throw response;
            }

            if (response.status === 204) {
                return {} as T;
            }

            return parseResponse(response);
        } catch (error: any) {
            if (error.status === 401) {
                if (authContext?.isAuthenticated()) {
                    authContext?.logout();
                } else {
                    authContext?.signinRedirect();
                }
            }
            if (error.status === 500) {
                push("/error", { error });
            }

            setLoading(false);
            throw await parseResponse(error);
        }
    }

    async function get<T>(url: string): Promise<T> {
        return common(url, "GET");
    }

    async function post<T>(url: string, body: any): Promise<T> {
        return common(url, {
            method: "POST",
            body: JSON.stringify(body),
        });
    }

    async function download<TError>(url: string): Promise<null | TError> {
        const response = await fetch(domainUrl + url, getHeaders({ method: "GET" }));

        if (!response.ok) {
            toast.error("Download failed.");
            return parseResponse(response);
        }

        const file = await response.blob();
        const filename = response.headers.get("content-disposition")
            ?.split(";")
            ?.find(n => n.includes("filename="))
            ?.replace("filename=", "")
            ?.replaceAll("\"", "")
            .trim();

        const downloadController = document.createElement("a");
        const downloadUrl = URL.createObjectURL(file);
        downloadController.href = downloadUrl;
        downloadController.download = filename ?? "file";
        downloadController.click();

        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
        }, 100);

        return null;
    }

    return { loading, get, post, download };
};

export { useAPI };

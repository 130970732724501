import { AUTH_KEY } from "./AuthService";

export const getToken = () => {
    const accessToken = sessionStorage.getItem(AUTH_KEY);

    if (accessToken) {
        const tokenParse = JSON.parse(accessToken);
        const tokenExpiry = parseInt(tokenParse.expires_at || "0", 10);

        if (tokenExpiry * 1000 > new Date().getTime()) {
            return tokenParse.access_token;
        }
    }

    return null;
};

import { XCircleIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Result } from "../../@types/Result";
import Logo from "../../Assets/Images/icon_blue.png";
import { UnauthenticatedLayout } from "../../Components/UnauthenticatedLayout";
import { useAPI } from "../../Hooks/useAPI";
import { useQuery } from "../../Hooks/useQuery";

export interface CompleteUserInviteResponse {}

const UserInvitePage = () => {
    const { get, post } = useAPI();
    const query = useQuery();
    const history = useHistory();
    const code = query.get("code") ?? "";
    const email = query.get("email") ?? "";

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState<string[]>([]);

    const errRedirect = (err: string) => {
        toast.error(err);
        setTimeout(() => {
            history.push("/");
        }, 3000);
    };

    const checkInviteIsValid = () => {
        get<Result<boolean>>(`user/invite/check?email=${email}&code=${code}`).then(r => {
            if (r.data === false) {
                errRedirect(`Invite is not valid. ${r.errors.concat()}`);
            }
        });
    };

    useEffect(() => {
        checkInviteIsValid();
    }, []);

    const submitForm = () => {
        setErrors([]);

        if (!code || !email) {
            // We somehow ended up with an invalid form. This shouldn't happen.
            errRedirect("Something went wrong.");
            return;
        }

        if (!password) {
            setErrors(["Please enter a password."]);
            return;
        }

        if (!confirmPassword) {
            setErrors(["Please confirm your password."]);
            return;
        }

        post<Result<CompleteUserInviteResponse>>("user/invite", {
            code,
            email,
            password,
            confirmPassword,
        }).then(r => {
            if (!r.successful) {
                setErrors(r.errors);
            } else {
                toast.success("Signup successful!");
                setTimeout(() => {
                    history.push("/");
                }, 3000);
            }
        }).catch(() => {
            setErrors(["Something went wrong."]);
        });
    };

    const Errors = () => {
        if (errors && errors.length > 0) {
            const errorMessage = errors.length > 1
                ? `There were ${errors.length} errors with your submission`
                : "There was 1 error with your submission";

            return (
                <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">{errorMessage}</h3>
                            <div className="mt-2 text-sm text-red-700">
                                <ul className="list-disc pl-5 space-y-1">
                                    {errors.map(e => (
                                        <li key={e}>{e}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <></>
        );
    };

    return (
        <UnauthenticatedLayout>
            <>
                <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-white 2xl:filter 2xl:drop-shadow-md">
                    <div className="flex flex-col h-full justify-between">
                        <div className="flex justify-between items-center mx-auto w-full max-w-sm lg:w-96">
                            <div className="w-auto">
                                <img className="inline-block h-10 mr-1" src={Logo} alt="Aitemology Logo" />
                                <h3 className="inline font-brand align-middle text-lg">
                                    Aitemology&reg;
                                </h3>
                            </div>
                            <button type="button" className="inline-flex h-9 items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-secondary-700 bg-secondary-100 hover:bg-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span>Have a question?</span>
                            </button>
                        </div>
                        <div className="mx-auto w-full max-w-sm lg:w-96">
                            <div>
                                <h2 className="mt-6 mb-4 font-brand text-3xl text-gray-900">
                                    Create your account
                                </h2>
                            </div>

                            <Errors />

                            <div className="mt-8">
                                <div className="mt-6">
                                    <div className="my-6">
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            Email address
                                        </label>
                                        <div className="mt-1">
                                            <input id="email" disabled name="email" type="email" value={email} className="appearance-none block w-full text-gray-500 px-3 py-2 border border-gray-200 rounded-md shadow-sm placeholder-gray-300 focus:outline-none sm:text-sm" />
                                        </div>
                                    </div>

                                    <div className="my-6">
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            Password
                                        </label>
                                        <div className="mt-1">
                                            <input onChange={(e) => setPassword(e.target.value)} type="password" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-700 focus:border-primary-700 sm:text-sm" />
                                        </div>
                                    </div>

                                    <div className="my-6">
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            Confirm Password
                                        </label>
                                        <div className="mt-1">
                                            <input onChange={(e) => setConfirmPassword(e.target.value)} type="password" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-700 focus:border-primary-700 sm:text-sm" />
                                        </div>
                                    </div>

                                    <div className="my-8">
                                        <button type="submit" onClick={submitForm} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-700 hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-700">
                                            Signup
                                        </button>
                                    </div>

                                    <div className="flex my-6 items-center justify-left">
                                        <div className="text-sm">
                                            <Link to="/" className="font-medium text-primary-700  hover:text-primary-800">
                                                Already have an account?
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <a className="mx-auto" href="https://www.linkedin.com/company/aitemology/">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                                    <g>
                                        <path d="M34,2.5v29A2.5,2.5,0,0,1,31.5,34H2.5A2.5,2.5,0,0,1,0,31.5V2.5A2.5,2.5,0,0,1,2.5,0h29A2.5,2.5,0,0,1,34,2.5ZM10,13H5V29h5Zm.45-5.5A2.88,2.88,0,0,0,7.59,4.6H7.5a2.9,2.9,0,0,0,0,5.8h0a2.88,2.88,0,0,0,2.95-2.81ZM29,19.28c0-4.81-3.06-6.68-6.1-6.68a5.7,5.7,0,0,0-5.06,2.58H17.7V13H13V29h5V20.49a3.32,3.32,0,0,1,3-3.58h.19c1.59,0,2.77,1,2.77,3.52V29h5Z" fill="#0a66c2" />
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:block relative w-0 flex-1">
                    <div className="flex h-full flex-wrap content-center px-20">
                        <div className="block mx-auto my-8 w-full">
                            <h1 className="font-brand text-5xl text-gray-50 text-right">
                                Welcome to our Portal
                            </h1>
                        </div>
                        <div className="block mx-auto my-3 w-full">
                            <h2 className="font-brand text-xl text-gray-50 text-right">Our portal has been designed to digitally distribute our aitems&reg;.</h2>
                        </div>
                        <div className="block mx-auto my-3 w-full">
                            <h2 className="font-brand text-xl text-gray-50 text-right">Please create your account here to gain access to your aitems&reg;.</h2>
                        </div>
                    </div>
                </div>
            </>
        </UnauthenticatedLayout>
    );
};

export { UserInvitePage };

import React, { Dispatch, Fragment, SetStateAction } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import LogoFull from "../../../../Assets/Images/logo_full.png";
import { NavigationItems } from "../navigation-items";
import { SideNavLink } from "../SideNavLink";
import { Favourites } from "../DesktopSideNav/Components/Favourites";
import { RecentDownloads } from "../DesktopSideNav/Components/RecentDownloads";

interface MobileSideNavProps {
  sidebarOpen: boolean,
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
}

/*
  TODO: This entire file will most likely be reduced and de-duplicated with the DesktopSideNav component,
        but for now they're separate components.
*/

const MobileSideNav = (props: MobileSideNavProps): JSX.Element => (
    <Transition.Root show={props.sidebarOpen} as={Fragment}>
        <Dialog
            as="div"
            static
            className="fixed inset-0 z-40 flex md:hidden"
            open={props.sidebarOpen}
            onClose={props.setSidebarOpen}
        >
            <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
            >
                <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-primary-700">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="absolute top-0 right-0 pt-2 -mr-12">
                            <button
                                type="button"
                                className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                onClick={() => props.setSidebarOpen(false)}
                            >
                                <span className="sr-only">Close sidebar</span>
                                <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                            </button>
                        </div>
                    </Transition.Child>
                    <div className="flex items-center flex-shrink-0 px-4">
                        <img
                            className="w-auto h-8"
                            src={LogoFull}
                            alt="Aitemology Logo"
                        />
                    </div>
                    <div className="flex-1 h-0 mt-5 space-y-8 overflow-y-auto">
                        <nav className="px-2 space-y-1">
                            {NavigationItems.map((item) => <SideNavLink key={item.name} item={item} />)}
                        </nav>
                        <Favourites />
                        <RecentDownloads />
                    </div>
                </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true" />
        </Dialog>
    </Transition.Root>
);

export { MobileSideNav };

import { ClockIcon, DocumentTextIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useListener } from "react-bus";
import { NavLink } from "react-router-dom";
import { AitemFile } from "../../../../../../@types/File";
import { PaginatedResult } from "../../../../../../@types/Result";
import { useAPI } from "../../../../../../Hooks/useAPI";
import { FileDownload } from "../../../../../File/FileDownload";
import { SideNavHeader } from "../../../SideNavHeader";
import { SideNavLink } from "../../../SideNavLink";

const RecentDownloads = () => {
    const { post } = useAPI();
    const [downloads, setDownloads] = useState<AitemFile[]>([]);

    const downloadsRequest = {
        filters: {
            isUserDownload: { value: true },
        },
        paginationParameters: {
            pageNumber: 1,
            orderByProperty: "LastUserDownloadDate",
            isDescending: true,
            itemsPerPage: 5,
        },
    };

    const updateDownloads = () => {
        post<PaginatedResult<AitemFile>>("files", downloadsRequest).then(rows => {
            setDownloads(rows.data);
        });
    };

    useEffect(() => {
        updateDownloads();
    }, []);

    useListener("fileDownloaded", updateDownloads);

    return (
        <div className="flex-1 px-2 space-y-3">
            <div className="mt-1 space-y-1" aria-labelledby="favourites-headline">
                <SideNavHeader text="Recent Downloads" icon={ClockIcon} />
                { downloads && downloads.length > 0
                    ? downloads.map((file) => (
                        <FileDownload
                            documentId={file.id}
                            fileName={file.name}
                            size={file.fileSizeBytes}
                            format={file.fileType}
                        >
                            <SideNavLink
                                key={file.id}
                                item={{
                                    name: file.name,
                                    href: `/aitem-files/${file.id}`,
                                    pageComponent: () => <></>,
                                    hideNav: true,
                                    icon: DocumentTextIcon,
                                }}
                                isDisabled
                            />
                        </FileDownload>))
                    : <span className="text-primary-200 group flex items-center px-2 ml-4 py-2 text-xs">No downloads.</span>}
                <NavLink
                    to="/aitem-files?downloads=true"
                    className="flex items-center px-3 py-2 text-sm font-medium rounded-md text-primary-100 group hover:bg-primary-600"
                >
                    See more...
                </NavLink>
            </div>
        </div>
    );
};

export { RecentDownloads };

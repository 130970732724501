/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-prop-types */
import React, { Dispatch, SetStateAction } from "react";
import { LoadingSpinner } from "../../../../Components/Core/LoadingSpinner";
import { PaginatedResult } from "../../../../@types/Result";
import { DesktopTablePagination } from "../../../../Components/Table/DesktopTablePagination";
import { MobileTablePagination } from "../../../../Components/Table/MobileTablePagination";
import { SortParams } from "../../../../@types/SortParams";
import { CompanyUser, FilterParams } from "../../../../@types/CompanyUser";
import { DesktopFilters } from "../DesktopFilters";
import { MobileFilters } from "../MobileFilters";
import { ReSendInvite } from "../ReSendInvite";
import { UserState } from "../../../../@types/UserState";
import { ToggleUserState } from "../ToggleUserState";
import { SortableTableHeader } from "../../../../Components/Table/SortableTableHeader";

interface Props
{
    rows: PaginatedResult<CompanyUser>,
    loading: boolean,
    filterValues: FilterParams,
    activeSortColumn: string,
    activeSortDirection: boolean,
    navigatePageCallback: Dispatch<SetStateAction<number>>,
    sortCallback: Dispatch<SetStateAction<SortParams>>,
    onSearch: (evt: string) => void,
    onChange: () => void,
}

const DesktopTable = (props: Props): JSX.Element => (
    <>
        <DesktopFilters
            filterValues={props.filterValues}
            onSearch={props.onSearch}
        />

        {props?.rows?.data?.length > 0
            ? (
                <table className="min-w-full border divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <SortableTableHeader
                                name="Forename"
                                sortName="forename"
                                activeSort={props.activeSortColumn}
                                activeSortDirection={props.activeSortDirection}
                                changeSortCallback={props.sortCallback}
                            />
                            <SortableTableHeader
                                name="Surname"
                                sortName="surname"
                                activeSort={props.activeSortColumn}
                                activeSortDirection={props.activeSortDirection}
                                changeSortCallback={props.sortCallback}
                            />
                            <SortableTableHeader
                                name="Email address"
                                sortName="emailAddress"
                                activeSort={props.activeSortColumn}
                                activeSortDirection={props.activeSortDirection}
                                changeSortCallback={props.sortCallback}
                            />
                            <SortableTableHeader
                                name="Job role"
                                sortName="jobRole"
                                activeSort={props.activeSortColumn}
                                activeSortDirection={props.activeSortDirection}
                                changeSortCallback={props.sortCallback}
                            />
                            <SortableTableHeader
                                name="Department"
                                sortName="department"
                                activeSort={props.activeSortColumn}
                                activeSortDirection={props.activeSortDirection}
                                changeSortCallback={props.sortCallback}
                            />
                            <SortableTableHeader
                                name="Status"
                                sortName="userState"
                                activeSort={props.activeSortColumn}
                                activeSortDirection={props.activeSortDirection}
                                changeSortCallback={props.sortCallback}
                            />
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider cursor-pointer"
                            >
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props?.rows?.data && props.rows.data.map((user, i) => (
                            <tr key={user.id} className={i % 2 === 0 ? "bg-white hover:bg-gray-100" : "bg-gray-50 hover:bg-gray-100"}>
                                <td className="px-6 py-4 text-sm text-gray-500 truncate">
                                    <span>{user.forename}</span>
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-500 truncate">
                                    <span>{user.surname}</span>
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-500 truncate">
                                    <span>{user.emailAddress}</span>
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-500 truncate">
                                    <span>{user.jobRole}</span>
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-500 truncate">
                                    <span>{user.department}</span>
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-500 truncate">
                                    <span className="text-sm text-gray-500">
                                        {UserState[user.userState]}
                                    </span>
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-500 truncate">
                                    {user.self === false && (
                                        <>
                                            <ToggleUserState
                                                userId={user.id}
                                                email={user.emailAddress}
                                                state={user.userState}
                                                onToggle={props.onChange}
                                            />
                                            {user.userState === UserState.Pending && (
                                                <ReSendInvite
                                                    className="ml-1"
                                                    userId={user.id}
                                                    email={user.emailAddress}
                                                />
                                            )}
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>)
            : props.loading
                ? <div className="py-3 bg-white"><LoadingSpinner /></div>
                : (
                    <div className="flex flex-col items-center w-full px-3 py-12 bg-white border-l border-r">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-48 h-48" viewBox="0 0 800 800">
                            <path d="M411.146 142.174h-174.51a15.018 15.018 0 00-15 15v387.85l-2 .61-42.81 13.11a8.007 8.007 0 01-9.99-5.31l-127.34-415.95a8.003 8.003 0 015.31-9.99l65.97-20.2 191.25-58.54 65.97-20.2a7.99 7.99 0 019.99 5.3l32.55 106.32z" fill="#f2f2f2" />
                            <path d="M449.226 140.174l-39.23-128.14a16.994 16.994 0 00-21.23-11.28l-92.75 28.39-191.24 58.55-92.75 28.4a17.015 17.015 0 00-11.28 21.23l134.08 437.93a17.027 17.027 0 0016.26 12.03 16.79 16.79 0 004.97-.75l63.58-19.46 2-.62v-2.09l-2 .61-64.17 19.65a15.015 15.015 0 01-18.73-9.95L2.666 136.734a14.98 14.98 0 019.95-18.73l92.75-28.4 191.24-58.54 92.75-28.4a15.156 15.156 0 014.41-.66 15.015 15.015 0 0114.32 10.61l39.05 127.56.62 2h2.08z" fill="#3f3d56" />
                            <path d="M122.68 127.82a9.016 9.016 0 01-8.61-6.366l-12.88-42.072a8.999 8.999 0 015.97-11.24L283.1 14.278a9.009 9.009 0 0111.24 5.971l12.88 42.072a9.01 9.01 0 01-5.97 11.241l-175.94 53.864a8.976 8.976 0 01-2.63.395z" fill="#004E72" /><circle cx="190.154" cy="24.955" r="20" fill="#004E72" /><circle cx="190.154" cy="24.955" r="12.665" fill="#fff" />
                            <path d="M602.636 582.174h-338a8.51 8.51 0 01-8.5-8.5v-405a8.51 8.51 0 018.5-8.5h338a8.51 8.51 0 018.5 8.5v405a8.51 8.51 0 01-8.5 8.5z" fill="#e6e6e6" />
                            <path d="M447.136 140.174h-210.5a17.024 17.024 0 00-17 17v407.8l2-.61v-407.19a15.018 15.018 0 0115-15h211.12zm183.5 0h-394a17.024 17.024 0 00-17 17v458a17.024 17.024 0 0017 17h394a17.024 17.024 0 0017-17v-458a17.024 17.024 0 00-17-17zm15 475a15.018 15.018 0 01-15 15h-394a15.018 15.018 0 01-15-15v-458a15.018 15.018 0 0115-15h394a15.018 15.018 0 0115 15z" fill="#3f3d56" />
                            <path d="M525.636 184.174h-184a9.01 9.01 0 01-9-9v-44a9.01 9.01 0 019-9h184a9.01 9.01 0 019 9v44a9.01 9.01 0 01-9 9z" fill="#004E72" />
                            <circle cx="433.636" cy="105.174" r="20" fill="#004E72" />
                            <circle cx="433.636" cy="105.174" r="12.182" fill="#fff" />
                        </svg>
                        <p className="text-gray-700">
                            No users added yet, you can invite users using the &apos;Invite User&apos; button above.
                        </p>
                    </div>)}

        {props?.rows?.data?.length > 0
            ? (
                <DesktopTablePagination
                    totalItemsCount={props.rows.totalResultsCount}
                    itemsOnPage={props.rows.resultsCount}
                    pageNumber={props.rows.pageNumber}
                    itemsPerPage={props.rows.itemsPerPage}
                    totalPages={props.rows.totalPages}
                    navigatePageCallback={props.navigatePageCallback}
                />)
            : <></>}
    </>
);

const MobileTable = (props: Props): JSX.Element => (
    <div className="px-3 rounded-md overflow-none">
        <MobileFilters
            filterValues={props.filterValues}
            onSearch={props.onSearch}
        />
        {props?.rows?.data?.length > 0
            ? (
                <ul>
                    {props.rows.data.map((user) => (
                        <li key={user.id} className="my-2 bg-white border border-gray-300 divide-y divide-gray-200 rounded-lg">
                            <div className="px-6 py-3 space-y-1">
                                <div className="flex items-start justify-between border-b border-gray-300 mb-3">
                                    <p className="text-primary-600 mb-1">{`${user.surname}, ${user.forename}`}</p>
                                </div>
                                <div className="flex flex-col justify-between">
                                    <span className="block text-sm text-gray-600 uppercase">{user.emailAddress}</span>
                                </div>
                                <div className="flex flex-col justify-between">
                                    <span className="block text-sm text-gray-600 uppercase">{user.jobRole}</span>
                                </div>
                                <div className="flex flex-col justify-between">
                                    <span className="block text-sm text-gray-600 uppercase">{user.department}</span>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>)
            : props.loading
                ? <div className="py-3 bg-white"><LoadingSpinner /></div>
                : (
                    <div className="flex flex-col items-center w-full px-3 py-12 my-2 bg-white border rounded">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-36 h-36" viewBox="0 0 800 800">
                            <path d="M411.146 142.174h-174.51a15.018 15.018 0 00-15 15v387.85l-2 .61-42.81 13.11a8.007 8.007 0 01-9.99-5.31l-127.34-415.95a8.003 8.003 0 015.31-9.99l65.97-20.2 191.25-58.54 65.97-20.2a7.99 7.99 0 019.99 5.3l32.55 106.32z" fill="#f2f2f2" />
                            <path d="M449.226 140.174l-39.23-128.14a16.994 16.994 0 00-21.23-11.28l-92.75 28.39-191.24 58.55-92.75 28.4a17.015 17.015 0 00-11.28 21.23l134.08 437.93a17.027 17.027 0 0016.26 12.03 16.79 16.79 0 004.97-.75l63.58-19.46 2-.62v-2.09l-2 .61-64.17 19.65a15.015 15.015 0 01-18.73-9.95L2.666 136.734a14.98 14.98 0 019.95-18.73l92.75-28.4 191.24-58.54 92.75-28.4a15.156 15.156 0 014.41-.66 15.015 15.015 0 0114.32 10.61l39.05 127.56.62 2h2.08z" fill="#3f3d56" />
                            <path d="M122.68 127.82a9.016 9.016 0 01-8.61-6.366l-12.88-42.072a8.999 8.999 0 015.97-11.24L283.1 14.278a9.009 9.009 0 0111.24 5.971l12.88 42.072a9.01 9.01 0 01-5.97 11.241l-175.94 53.864a8.976 8.976 0 01-2.63.395z" fill="#004E72" /><circle cx="190.154" cy="24.955" r="20" fill="#004E72" /><circle cx="190.154" cy="24.955" r="12.665" fill="#fff" />
                            <path d="M602.636 582.174h-338a8.51 8.51 0 01-8.5-8.5v-405a8.51 8.51 0 018.5-8.5h338a8.51 8.51 0 018.5 8.5v405a8.51 8.51 0 01-8.5 8.5z" fill="#e6e6e6" />
                            <path d="M447.136 140.174h-210.5a17.024 17.024 0 00-17 17v407.8l2-.61v-407.19a15.018 15.018 0 0115-15h211.12zm183.5 0h-394a17.024 17.024 0 00-17 17v458a17.024 17.024 0 0017 17h394a17.024 17.024 0 0017-17v-458a17.024 17.024 0 00-17-17zm15 475a15.018 15.018 0 01-15 15h-394a15.018 15.018 0 01-15-15v-458a15.018 15.018 0 0115-15h394a15.018 15.018 0 0115 15z" fill="#3f3d56" />
                            <path d="M525.636 184.174h-184a9.01 9.01 0 01-9-9v-44a9.01 9.01 0 019-9h184a9.01 9.01 0 019 9v44a9.01 9.01 0 01-9 9z" fill="#004E72" />
                            <circle cx="433.636" cy="105.174" r="20" fill="#004E72" />
                            <circle cx="433.636" cy="105.174" r="12.182" fill="#fff" />
                        </svg>
                        <p className="text-gray-700">
                            No users added yet, you can invite users using the &apos;Invite User&apos; button above.
                        </p>
                    </div>)}
        <MobileTablePagination
            pageNumber={props.rows.pageNumber}
            totalPages={props.rows.totalPages}
            navigatePageCallback={props.navigatePageCallback}
        />
    </div>
);

const UsersTable = (props: Props) => (props ? (
    <>
        <div className="hidden overflow-hidden lg:block">
            <DesktopTable
                rows={props.rows}
                loading={props.loading}
                filterValues={props.filterValues}
                activeSortColumn={props.activeSortColumn}
                activeSortDirection={props.activeSortDirection}
                navigatePageCallback={props.navigatePageCallback}
                sortCallback={props.sortCallback}
                onSearch={props.onSearch}
                onChange={props.onChange}
            />
        </div>
        <div className="block overflow-hidden lg:hidden sm:rounded-lg">
            <MobileTable
                rows={props.rows}
                loading={props.loading}
                filterValues={props.filterValues}
                activeSortColumn={props.activeSortColumn}
                activeSortDirection={props.activeSortDirection}
                navigatePageCallback={props.navigatePageCallback}
                sortCallback={props.sortCallback}
                onSearch={props.onSearch}
                onChange={props.onChange}
            />
        </div>
    </>
) : <></>);

export { UsersTable };

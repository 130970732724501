import { IDENTITY_CONFIG, METADATA_OIDC } from "./AuthConstants";
import { UserManager, WebStorageStateStore } from "oidc-client";

export const AUTH_KEY = `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`;

class AuthService {
    userManager: UserManager;

    constructor() {
        this.userManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ store: window.sessionStorage }), // TODO: Implement cookie storage
            metadata: METADATA_OIDC,
        });

        /* Upon successful load of an authenticated user */
        this.userManager.events.addUserLoaded(() => {
            if (window.location.href.indexOf("login-callback") !== -1) {
                window.location.replace("/");
            }
        });

        /* Upon access token expiration, fire a silent refresh. Uses an invisible iframe. */
        this.userManager.events.addAccessTokenExpired(() => this.userManager.signinSilent());
    }

    createSigninRequest = () => this.userManager.createSigninRequest();

    signinRedirect = () => {
        localStorage.setItem("redirectUri", window.location.pathname);
        this.userManager.signinRedirect({});
    };

    logout = () => {
        this.userManager.signoutRedirect({
            id_token_hint: localStorage.getItem("id_token"),
        });
        this.userManager.clearStaleState();
    };

    signinRedirectCallback = () => {
        this.userManager.signinRedirectCallback();
    };

    signinSilentCallback = () => {
        this.userManager.signinSilentCallback();
    };

    isAuthenticated = () => {
        const storage = sessionStorage.getItem(AUTH_KEY);
        if (!storage) return false;

        const oidcStorage = JSON.parse(storage);
        return (!!oidcStorage && !!oidcStorage.access_token);
    };

    signoutRedirectCallback = () => {
        this.userManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            this.signinRedirect();
        });
        this.userManager.clearStaleState();
    };
}

export { AuthService };

import React, { useState } from "react";
import { DownloadIcon } from "@heroicons/react/outline";
import { LoadingSpinner } from "../../Core/LoadingSpinner";
import { DownloadConfirmationModal } from "../DownloadConfirmationModal";
import { useAPI } from "../../../Hooks/useAPI";
import { classNames } from "../../../Utils/Helpers";
import { useBus } from "react-bus";

interface Props {
    documentId: number,
    fileName: string,
    format: string,
    size: number,
    className?: string,
    isUserDownload: boolean
}

const FileDownloadButton = (props: Props) => {
    const { download } = useAPI();
    const bus = useBus();
    const [loading, setLoading] = useState(false);
    const [waitingOnConfirmation, setWaitingOnConfirmation] = useState(false);

    const requestDownload = () => {
        setLoading(true);
        setWaitingOnConfirmation(true);
    };

    const downloadFile = () => {
        download(`files/${props.documentId}`)
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
            .finally(() => bus.emit("fileDownloaded"));
    };

    const downloadConfirmed = () => {
        setWaitingOnConfirmation(false);
        downloadFile();
    };

    const cancelDownload = () => {
        setLoading(false);
        setWaitingOnConfirmation(false);
    };

    const hoverText = () => {
        if (loading) return "loading";
        return props.isUserDownload ? "Download again" : "Download";
    };

    return (
        <button
            type="button"
            className={classNames(props.className ?? "flex-1")}
            {...{ title: hoverText() }}
            onClick={requestDownload}
        >
            { loading
                ? <LoadingSpinner className="inline-block w-6 h-6 m-2" />
                : <DownloadIcon className="inline-block w-6 h-6 m-2 text-primary-600" />}
            <DownloadConfirmationModal
                fileName={props.fileName}
                format={props.format}
                size={props.size}
                open={waitingOnConfirmation}
                confirmCallback={downloadConfirmed}
                cancelCallback={cancelDownload}
            />
        </button>
    );
};

export { FileDownloadButton };

import React, { useContext } from "react";
import { Route, Switch, useLocation } from "react-router";
import { LoginCallback } from "./Components/Auth/LoginCallback";
import { Logout } from "./Components/Auth/Logout";
import { NotFoundPage } from "./Pages/NotFoundPage";
import { AuthSilentRenew } from "./Components/Auth/AuthSilentRenew";
import { LogoutCallback } from "./Components/Auth/LogoutCallback";
import { NavigationItems } from "./Components/Layout/SideNav/navigation-items";
import { CompanyProfilePage } from "./Pages/CompanyProfilePage";
import { UserInvitePage } from "./Pages/UserInvitePage";
import { Layout } from "./Components/Layout";
import { AuthContext } from "./Context/AuthProvider";
import { LoadingSpinner } from "./Components/Core/LoadingSpinner";
import { DownloadLogPage } from "./Pages/DownloadLogPage";

const AppRouter: React.FC = (): JSX.Element => {
    const authProvider = useContext(AuthContext);
    const loc = useLocation();

    return (
        <Switch>
            <Route exact path="/404" component={NotFoundPage} />
            <Route exact path="/auth/logout" component={Logout} />
            <Route exact path="/auth/logout-callback" component={LogoutCallback} />
            <Route exact path="/auth/login-callback" component={LoginCallback} />
            <Route exact path="/auth/silent-renew" component={AuthSilentRenew} />
            <Route exact path="/user/invite" component={UserInvitePage} />
            {authProvider?.isAuthenticated() ? (
                <>
                    <Layout>
                        <>
                            {
                                NavigationItems.map(route => (
                                    <Route key={route.href} exact path={route.href} component={route.pageComponent} />
                                ))
                            }
                            <Route exact path="/company-profile" component={CompanyProfilePage} />
                            <Route exact path="/download-log" component={DownloadLogPage} />
                        </>
                    </Layout>
                </>
            ) : (
                <>
                    {loc.pathname !== "/user/invite" && authProvider?.signinRedirect()}
                    <div className="flex flex-row justify-center align-middle h-full">
                        <LoadingSpinner />
                    </div>
                </>
            )}
            <Route component={NotFoundPage} />
        </Switch>
    );
};

export { AppRouter };

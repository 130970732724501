import React from "react";
import { Link } from "react-router-dom";
import { NavigationItems } from "../navigation-items";
import LogoFull from "../../../../Assets/Images/logo_full.png";
import { SideNavLink } from "../SideNavLink";
import { Favourites } from "./Components/Favourites";
import { RecentDownloads } from "./Components/RecentDownloads";

const DesktopSideNav = (): JSX.Element => (
    <div className="hidden bg-primary-700 md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
            <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-4">
                    <Link to="/">
                        <img
                            className="w-auto h-10"
                            src={LogoFull}
                            alt="Aitemology Logo"
                        />
                    </Link>
                </div>
                <div className="flex flex-col mt-5 space-y-8">
                    <nav className="flex-1 px-2 space-y-1">
                        {NavigationItems.filter(x => !x.hideNav).map((item) => <SideNavLink key={item.name} item={item} />)}
                    </nav>
                    <Favourites />
                    <RecentDownloads />
                </div>
            </div>
        </div>
    </div>
);

export { DesktopSideNav };

import React from "react";
import { AppRouter } from "./AppRouter";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./Context/AuthProvider";
import { Provider as BusProvider } from "react-bus";

const App = (): JSX.Element => (
    <BusProvider>
        <AuthProvider>
            <Router>
                <AppRouter />
            </Router>
        </AuthProvider>
    </BusProvider>
);

export default App;

import React from "react";
import { classNames } from "../../../../Utils/Helpers";

interface Colours {
  background: string,
  text: string,
  icon: string
}

interface ColourMap {
  format: string,
  colours: Colours
}

const fileFormatColourMap: ColourMap[] = [
    {
        format: "docx",
        colours: {
            background: "bg-blue-100",
            text: "text-blue-800",
            icon: "text-blue-500",
        },
    },
    {
        format: "png",
        colours: {
            background: "bg-yellow-100",
            text: "text-yellow-800",
            icon: "text-yellow-500",
        },
    },
    {
        format: "pptx",
        colours: {
            background: "bg-red-100",
            text: "text-red-800",
            icon: "text-red-500",
        },
    },
    {
        format: "pdf",
        colours: {
            background: "bg-purple-100",
            text: "text-purple-800",
            icon: "text-purple-500",
        },
    },
    {
        format: "xls",
        colours: {
            background: "bg-green-100",
            text: "text-green-800",
            icon: "text-green-500",
        },
    },
    {
        format: "xlsm",
        colours: {
            background: "bg-green-100",
            text: "text-green-800",
            icon: "text-green-500",
        },
    },
    {
        format: "xlsb",
        colours: {
            background: "bg-green-100",
            text: "text-green-800",
            icon: "text-green-500",
        },
    },
    {
        format: "xlsx",
        colours: {
            background: "bg-green-100",
            text: "text-green-800",
            icon: "text-green-500",
        },
    },
];

interface Props {
  format: string
}

const FileTypeTag = (props: Props): JSX.Element => {
    const colors = fileFormatColourMap.filter(c => c.format === props.format);

    if (!colors[0]) {
        return (
            <></>
        );
    }

    const containerClasses = classNames(
        "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium",
        colors[0]?.colours.background,
        colors[0]?.colours.text,
    );

    const iconClasses = classNames("mr-1.5 h-2 w-2", colors[0]?.colours.icon);

    return (
        <span className={containerClasses}>
            <svg className={iconClasses} fill="currentColor" viewBox="0 0 8 8"><circle cx="4" cy="4" r="3" /></svg>
            .{props.format}
        </span>
    );
};

export { FileTypeTag };

import { SearchIcon, HeartIcon, DownloadIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";

import { FilterParams } from "../../../../@types/File";
import { useDebounce } from "../../../../Hooks/useDebounce";
import { ToggleButton, ToggleButtonGroupPosition } from "../../../../Components/ToggleButton";
import { DocumentFolder } from "../../../../@types/DocumentFolder";
import { useAPI } from "../../../../Hooks/useAPI";
import { Result } from "../../../../@types/Result";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

interface Props {
  filterValues: FilterParams,
  onFavouritesToggle: (val: boolean) => void,
  onDownloadsToggle: (val: boolean) => void,
  onSearch: (val: string) => void,
  onFolderChange: (val: React.ChangeEvent<HTMLSelectElement>) => void
}

const DesktopFilters = (props: Props): JSX.Element => {
    const history = useHistory();
    const { get } = useAPI();
    const [folders, setFolders] = useState<DocumentFolder[] | null>();
    const [searchTerm, setSearchTerm] = useState<string | undefined>(props.filterValues.search);
    const debounceSearch = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (debounceSearch !== undefined) {
            props.onSearch(debounceSearch);
        }

        get<Result<any>>("home/document-folders").then(r => {
            if (r.successful) {
                setFolders(r.data);
            } else {
                toast.error("Something went wrong.");
                setTimeout(() => {
                    history.replace("/");
                }, 3000);
            }
        });
    }, [debounceSearch]);

    return (
        <>
            <div className="bg-white px-6 py-3 flex border border-b-0 sm:rounded-t-lg">
                <div className="relative flex flex-grow text-gray-400 focus-within:text-gray-600 pr-4">
                    <label htmlFor="search_field" className="sr-only">
                        Search files
                    </label>
                    <div className="absolute inset-y-0 left-0 flex flex-none items-center pointer-events-none">
                        <SearchIcon className="w-5 h-5" aria-hidden="true" />
                    </div>
                    <input
                        id="search_field"
                        className="h-full py-2 pl-8 pr-3 flex-grow text-gray-900 border-transparent placeholder-gray-500  focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="Search files"
                        type="search"
                        name="search"
                        value={searchTerm}
                        onChange={(e) => { setSearchTerm(e.target.value); }}
                    />
                </div>
                <div className="flex-none flex justify-between">
                    <select
                        onChange={props.onFolderChange}
                        className="mr-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm rounded-md"
                        defaultValue=""
                        value={props.filterValues.folderId}
                    >
                        <option value="">All document folders</option>
                        {folders?.filter(x => x.parentFolderId === null && x.permitted).map(p => (
                            <>
                                <option value={p.id}>{p.name}</option>
                                {folders?.filter(x => x.parentFolderId === p.id).map(ch1 => (
                                    <>
                                        <option value={ch1.id}> &nbsp;&nbsp;&nbsp; {ch1.name}</option>
                                        {folders?.filter(x => x.parentFolderId === ch1.id).map(ch2 => (
                                            <option value={ch2.id}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ch2.name}</option>
                                        ))}
                                    </>
                                ))}
                            </>
                        ))}
                    </select>
                    <ToggleButton pos={ToggleButtonGroupPosition.Left} text="Favourites" icon={HeartIcon} onChange={props.onFavouritesToggle} checked={props.filterValues.favourites} />
                    <ToggleButton pos={ToggleButtonGroupPosition.Right} text="My downloads" icon={DownloadIcon} onChange={props.onDownloadsToggle} checked={props.filterValues.downloads} />
                </div>
            </div>
        </>
    );
};

export { DesktopFilters };

import React from "react";

interface Props {
    header: string
}

const PageHeader = (props: Props): JSX.Element => (
    <div className="pt-3 pb-1 mx-auto max-w-7xl lg:pt-4">
        <h1 className="text-2xl font-semibold text-gray-900">
            {props.header}
        </h1>
    </div>
);

export { PageHeader };

/* eslint-disable max-len */
import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "../../../../Utils/Helpers";
import { Link } from "react-router-dom";
import { ProfilePicture } from "./ProfilePicture";

const userNavigation = [
    { name: "Company profile", href: "/company-profile" },
    { name: "Download log", href: "/download-log" },
    { name: "Logout", href: "/auth/logout" },
];

const UserProfile = () => (
    <Menu as="div" className="relative ml-3">
        {({ open }) => (
            <>
                <div>
                    <Menu.Button className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                        <span className="sr-only">Open user menu</span>
                        <ProfilePicture />
                    </Menu.Button>
                </div>
                <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        static
                        className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        <Menu.Item key="change-password">
                            {({ active }) => (
                                <a
                                    href={process.env.REACT_APP_AUTH_CHANGE_PASSWORD_URL ?? "#"}
                                    className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700",
                                    )}
                                >
                                    Change password
                                </a>
                            )}
                        </Menu.Item>
                        {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                                {({ active }) => (
                                    <Link
                                        to={item.href}
                                        className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "block px-4 py-2 text-sm text-gray-700",
                                        )}
                                    >
                                        {item.name}
                                    </Link>
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            </>
        )}
    </Menu>
);

export { UserProfile };

import React, { useState, useEffect } from "react";
import { HeartIcon } from "@heroicons/react/solid";
import { HeartIcon as HeartIconOutline, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { useAPI } from "../../../../Hooks/useAPI";
import { UnitResult } from "../../../../@types/Result";
import { LoadingSpinner } from "../../../../Components/Core/LoadingSpinner";
import { toast } from "react-toastify";
import { ConfirmationModal } from "../../../../Components/Core/ConfirmationModal";
import { useBus } from "react-bus";

interface Props {
  documentId: number,
  isFavourite: boolean
}

const Favourite = (props: Props) => {
    const { post } = useAPI();
    const bus = useBus();
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [waitingOnConfirmation, setWaitingOnConfirmation] = useState(false);

    useEffect(() => {
        setActive(props.isFavourite);
    }, []);

    const submit = () => post<UnitResult>("files/favourite", { documentId: props.documentId });

    const favourite = () => {
        submit().then(() => {
            bus.emit("favouriteAdded");
            toast.success("Favourite added.");
            setActive(true);
            setLoading(false);
        });
    };

    const unfavourite = () => {
        setWaitingOnConfirmation(false);
        submit().then(() => {
            bus.emit("favouriteRemoved");
            toast.success("Favourite removed.");
            setActive(false);
            setLoading(false);
        });
    };

    const toggleFavourite = () => {
        setLoading(true);

        if (active) {
            setWaitingOnConfirmation(true);
        } else {
            favourite();
        }
    };

    const cancelUnfavourite = () => {
        setWaitingOnConfirmation(false);
        setLoading(false);
    };

    const formatHover = () => {
        if (loading) return "loading";
        return active ? "Remove favourite" : "Add favourite";
    };

    return (
        <button
            type="button"
            className="flex items-center justify-center w-full"
            onClick={toggleFavourite}
            {...{ title: formatHover() }}
        >
            <ConfirmationModal
                open={waitingOnConfirmation}
                confirmCallback={unfavourite}
                cancelCallback={cancelUnfavourite}
                header="Remove favourite?"
                body="Please confirm you're sure you wish to remove this Aitem as a favourite."
                closeText="Cancel"
                continueText="Remove Favourite"
                icon={QuestionMarkCircleIcon}
            />
            {
                loading && <LoadingSpinner className="w-6 h-6" />
            }
            {
                !loading && active
                    ? <HeartIcon className="w-6 h-6 cursor-pointer lg:m-2 text-primary-600" />
                    : <HeartIconOutline className="w-6 h-6 text-opacity-50 cursor-pointer lg:m-2 text-primary-600" />
            }
        </button>
    );
};

export { Favourite };

import React, { useState } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { LoadingSpinner } from "../../../../Components/Core/LoadingSpinner";
import { useAPI } from "../../../../Hooks/useAPI";
import { classNames } from "../../../../Utils/Helpers";
import { ConfirmationModal } from "../../../../Components/Core/ConfirmationModal";
import { UserState } from "../../../../@types/UserState";
import { Result } from "../../../../@types/Result";
import { toast } from "react-toastify";

interface Props {
    userId: number,
    state: UserState,
    email: string,
    className?: string,
    onToggle: () => void
}

interface UpdateStateResult {
    newState: UserState
}

const ToggleUserState = (props: Props) => {
    const { post } = useAPI();
    const [loading, setLoading] = useState(false);
    const [waitingOnConfirmation, setWaitingOnConfirmation] = useState(false);
    const [userState, setUserState] = useState<UserState>(props.state);

    const click = () => {
        setLoading(true);
        setWaitingOnConfirmation(true);
    };

    const toggleState = () => {
        post<Result<UpdateStateResult>>("company-profile/user/toggle-state", {
            userId: props.userId,
        })
            .then(r => {
                setLoading(false);
                if (r.successful) {
                    toast.success(`User ${userState === UserState.Active || userState === UserState.Pending ? "locked" : "unlocked"} successfully.`);
                    setUserState(r.data.newState);
                    props.onToggle();
                } else {
                    toast.error(r.errors.join(", "));
                }
            })
            .catch(() => setLoading(false));
    };

    const confirm = () => {
        setWaitingOnConfirmation(false);
        toggleState();
    };

    const cancel = () => {
        setLoading(false);
        setWaitingOnConfirmation(false);
    };

    return (
        <button
            type="button"
            title="Toggle user state"
            className={classNames(props.className ?? "flex-1")}
            onClick={click}
        >
            { loading
                ? <LoadingSpinner className="inline-block w-6 h-6 m-2" />
                : (
                    <button
                        type="button"
                        title={`User is ${UserState[userState]}. Click to ${userState === UserState.Active || userState === UserState.Pending ? "lock" : "unlock"} the account.`}
                        className={classNames(
                            "inline-flex justify-center px-3 py-1 text-base font-medium text-white border border-transparent rounded-md shadow-sm disabled:opacity-50  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2 sm:text-sm",
                            userState === UserState.Active || userState === UserState.Pending
                                ? "bg-red-400 hover:bg-red-500 focus:ring-red-500"
                                : "bg-green-500 hover:bg-green-600 focus:ring-green-600",
                        )}
                    >
                        {userState === UserState.Active || userState === UserState.Pending ? "Lock account" : "Unlock account"}
                    </button>
                )}
            <ConfirmationModal
                header="Are you sure?"
                body={`Please confirm you wish to ${userState === UserState.Active || userState === UserState.Pending ? "lock" : "unlock"} ${props.email}.`}
                continueText={userState === UserState.Active || userState === UserState.Pending ? "Lock account" : "Unlock account"}
                closeText="Cancel"
                icon={QuestionMarkCircleIcon}
                open={waitingOnConfirmation}
                confirmCallback={confirm}
                cancelCallback={cancel}
            />
        </button>
    );
};

export { ToggleUserState };

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useAPI } from "../../Hooks/useAPI";
import { CompanyUser, FilterParams } from "../../@types/CompanyUser";
import { PaginatedResult, Result } from "../../@types/Result";
import { SortParams } from "../../@types/SortParams";
import { UsersTable } from "./Components/UsersTable";
import { Company } from "../../@types/Company";
import { SendInvite } from "./Components/SendInvite";
import { PageHeader } from "../../Components/Core/PageHeader";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const CompanyProfilePage = () => {
    const query = useQuery();

    const [company, setCompany] = useState<Company>();
    const [companyUsers, setCompanyUsers] = useState<any>({});
    const [filterValues, setFilterValues] = useState<FilterParams>({ search: query.get("search") ?? undefined });

    const [sortValue, setSortValue] = useState<SortParams>({ isDescending: false, sortColumn: "forename" });
    const [pageIndex, setPageIndexValue] = useState(parseInt(query.get("page") ?? "1", 10));
    const [loading, setLoading] = useState(true);

    const { get, post } = useAPI();
    const { replace } = useHistory();

    const loadData = () => {
        const paginationRequest: any = {
            filters: {
                search: { value: filterValues.search },
            },
            paginationParameters: {
                pageNumber: pageIndex,
                OrderByProperty: sortValue.sortColumn,
                IsDescending: sortValue.isDescending,
            },
        };

        post<PaginatedResult<CompanyUser>>("company-profile", paginationRequest).then(result => {
            setCompanyUsers(result);
            setLoading(false);
        });

        const params = new URLSearchParams();

        // swap all filterValues to an iterate
        if (filterValues?.search) {
            params.append("search", filterValues.search.toString());
        }

        if (sortValue?.sortColumn) {
            params.append("sortBy", sortValue.sortColumn);
        }

        if (sortValue?.isDescending) {
            params.append("sortDesc", sortValue.isDescending ? "true" : "false");
        }

        if (pageIndex) {
            params.append("page", pageIndex.toString());
        }

        replace({ search: params.toString() });
    };

    const onSearch = (value: string) => {
        setFilterValues(curr => ({
            ...curr,
            search: value,
        }));
        setPageIndexValue(1);
    };

    const onChange = () => {
        loadData();
    };

    useEffect(() => {
        get<Result<Company>>("company-profile").then(result => {
            if (result.successful) {
                setCompany(result.data);
            }
        });
    }, []);

    useEffect(loadData, [filterValues, pageIndex, sortValue]);

    return (
        <>
            <PageHeader header="Company profile" />
            <div className="space-y-8 mt-4">
                <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Company Information</h3>
                        <p className="max-w-2xl mt-1 text-sm text-gray-500">Company and licence details.</p>
                    </div>
                    <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Company name</dt>
                                <dd className="mt-1 text-sm text-gray-900">{company?.name ?? ""}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Licence version</dt>
                                <dd className="mt-1 text-sm text-gray-900">{company?.licenceVersion ?? ""}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Licence expiry</dt>
                                <dd className="mt-1 text-sm text-gray-900">{company?.licenceExpiry ?? ""}</dd>
                            </div>
                            {company?.canAddUsers && (
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Permissions</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{company?.canAddUsers ? "Invite Users" : ""}</dd>
                                </div>
                            )}
                        </dl>
                    </div>
                </div>
                <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div className="flex justify-between px-4 py-5 sm:px-6">
                        <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Company Users</h3>
                            <p className="max-w-2xl mt-1 text-sm text-gray-500">Invite and manage your company&apos;s
                                users.
                            </p>
                        </div>
                        <div className="flex-shrink-0 mt-2 ml-4">
                            <SendInvite
                                companyId={company === undefined ? 0 : company.id}
                                completeCallback={loadData}
                                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-700 hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-700"
                            />
                        </div>
                    </div>
                    <div className="border-t border-gray-200">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <UsersTable
                                        loading={loading}
                                        rows={companyUsers}
                                        filterValues={filterValues}
                                        activeSortColumn={sortValue.sortColumn}
                                        activeSortDirection={sortValue.isDescending}
                                        navigatePageCallback={setPageIndexValue}
                                        sortCallback={setSortValue}
                                        onSearch={onSearch}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { CompanyProfilePage };

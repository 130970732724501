import { NavItem } from "../../../@types/NavItem";
import { HomePage } from "../../../Pages/HomePage";
import { PlaybookPrimerPage } from "../../../Pages/PlaybookPrimerPage";
import { AitemFilesPage } from "../../../Pages/AitemFilesPage";
import { FAQsAndHelpPage } from "../../../Pages/FAQsAndHelpPage";
import { LegalDisclaimerPage } from "../../../Pages/LegalDisclaimerPage";

import { ArrowCircleRightIcon,
    DocumentTextIcon,
    DocumentDuplicateIcon,
    QuestionMarkCircleIcon,
    PaperClipIcon } from "@heroicons/react/outline";

const NavigationItems: NavItem[] = [
    { name: "Home page", hideNav: true, href: "/", icon: ArrowCircleRightIcon, pageComponent: HomePage },
    { name: "Playbook primer", href: "/primer", icon: DocumentTextIcon, pageComponent: PlaybookPrimerPage },
    { name: "Aitem files", href: "/aitem-files", icon: DocumentDuplicateIcon, pageComponent: AitemFilesPage },
    { name: "FAQs and help", href: "/faq", icon: QuestionMarkCircleIcon, pageComponent: FAQsAndHelpPage },
    { name: "Legal disclaimer", href: "/legal", icon: PaperClipIcon, pageComponent: LegalDisclaimerPage },
];

export { NavigationItems };

/* eslint-disable max-len */
import React from "react";
import { classNames } from "../../Utils/Helpers";

enum ToggleButtonGroupPosition {
  Left,
  Default,
  Right,
  Ungrouped
}

interface ToggleButtonProps {
  checked: boolean,
  className?: string,
  text: string,
  pos: ToggleButtonGroupPosition,
  icon?: (props: React.ComponentProps<"svg">) => JSX.Element,
  onChange: (checked: boolean) => void
}

const ToggleButton = (props: ToggleButtonProps) => {
    const toggleChecked = () => props.onChange(!props.checked);

    const borderClass = (pos: ToggleButtonGroupPosition) => {
        if (pos === ToggleButtonGroupPosition.Left) {
            return "rounded-l-md border-r-0";
        }
        if (pos === ToggleButtonGroupPosition.Right) {
            return "rounded-r-md";
        }
        if (pos === ToggleButtonGroupPosition.Ungrouped) {
            return "rounded-md";
        }

        return "border-r-0 border-l-0";
    };

    const classes = classNames(
        props.className ?? "",
        "cursor-pointer relative inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-600 focus:border-primary-600",
        props.checked ? "bg-primary-600 text-white hover:bg-primary-700" : "bg-white text-gray-700 hover:bg-gray-50",
        borderClass(props.pos),
    );

    const iconClasses = classNames(
        "w-5 h-5 mr-1",
        props.checked ? "text-white" : "text-primary-600",
    );

    return (
        <div className="bg-white inline border-gray-200">
            <label className={classes}>
                <input onChange={toggleChecked} type="checkbox" checked={props.checked} className="hidden" />
                { props.icon && <props.icon className={iconClasses} /> }
                <span className="no-select">{props.text}</span>
            </label>
        </div>
    );
};

export { ToggleButtonGroupPosition, ToggleButton };

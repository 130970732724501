/* eslint-disable max-len */
import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DownloadIcon } from "@heroicons/react/outline";

import { formatBytes } from "../../../Utils/Formatters";
import { Link } from "react-router-dom";

interface Props {
    open: boolean,
    fileName: string,
    format: string,
    size: number,
    confirmCallback: () => void,
    cancelCallback: () => void
}

const DownloadConfirmationModal = (props: Props): JSX.Element => {
    const [open, setOpen] = useState(false);
    const cancelButtonRef = useRef(null);
    const [agreed, setAgreed] = useState(false);

    useEffect(() => {
        if (props.open) setOpen(true);
    }, [props]);

    const confirm = () => {
        if (!agreed) return;
        setAgreed(false);
        setOpen(false);
        props.confirmCallback();
    };

    const cancel = () => {
        setOpen(false);
        setAgreed(false);
        props.cancelCallback();
    };

    const agreementChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAgreed(e.target.checked);
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={cancel}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-secondary-100">
                                    <DownloadIcon className="h-6 w-6 text-secondary-700" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        File download
                                    </Dialog.Title>
                                    <div className="grid grid-cols-2 text-left px-2 my-3">
                                        <p className="my-2">Name:</p>
                                        <p className="my-2 text-sm text-gray-500">{props.fileName}</p>
                                        <p className="my-2">File format:</p>
                                        <p className="my-2 text-sm text-gray-500">{props.format}</p>
                                        <p className="my-2">File size:</p>
                                        <p className="my-2 text-sm text-gray-500">{formatBytes(props.size)}</p>
                                    </div>
                                    <div className="relative flex items-start my-5 px-2">
                                        <div className="flex items-center h-5">
                                            <input
                                                id="comments"
                                                aria-describedby="comments-description"
                                                name="comments"
                                                type="checkbox"
                                                className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                                                onChange={agreementChecked}
                                            />
                                        </div>
                                        <div className="ml-3 text-sm text-left">
                                            <label htmlFor="comments" className="font-medium text-gray-700">
                                                By downloading this aitem&reg; file, I agree to and accept Aitemology Ltd&apos;s <Link className="font-bold text-primary-600" to="/legal">Legal disclaimer</Link>.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                <button
                                    type="button"
                                    className="w-full disabled:opacity-50 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:col-start-2 sm:text-sm"
                                    onClick={confirm}
                                    disabled={!agreed}
                                >
                                    Download
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={cancel}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export { DownloadConfirmationModal };

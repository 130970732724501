import { ChevronDownIcon, ChevronUpIcon, MinusIcon } from "@heroicons/react/outline";
import React, { Dispatch, SetStateAction, useState } from "react";
import { SortParams } from "../../../@types/SortParams";

interface Props {
    name: string,
    sortName: string,
    activeSort: string,
    activeSortDirection: boolean,
    changeSortCallback: Dispatch<SetStateAction<SortParams>>
}

const SortableTableHeader = (props: Props): JSX.Element => {
    const [isDescending, setSort] = useState(props.activeSortDirection);

    const clickSort = () => {
        const val = !isDescending;
        setSort(val);
        props.changeSortCallback({
            sortColumn: props.sortName,
            isDescending: val,
        });
    };

    return (
        <th
            scope="col"
            className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase cursor-pointer"
            onClick={clickSort}
        >
            <span className="align-middle">{props.name}</span>
            {
                // eslint-disable-next-line no-nested-ternary
                props.activeSort !== props.sortName
                    ? <MinusIcon className="inline-block w-6 h-6 text-gray-400 align-middle" />
                    : isDescending
                        ? <ChevronUpIcon className="inline-block w-6 h-6 text-gray-400 align-middle" />
                        : <ChevronDownIcon className="inline-block w-6 h-6 text-gray-400 align-middle" />
            }
        </th>
    );
};

export { SortableTableHeader };

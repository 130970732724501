import React, { useEffect, useState } from "react";
import { UserIcon } from "@heroicons/react/solid";
import { Result } from "../../../../../@types/Result";
import { useAPI } from "../../../../../Hooks/useAPI";

interface UserProfile {
    fullName: string,
    gravatarHash: string,
}

const ProfilePicture = () => {
    const { get } = useAPI();
    const [profile, setProfile] = useState<UserProfile | undefined>();

    useEffect(() => {
        get<Result<UserProfile>>("account/profile").then(r => {
            if (r.successful) setProfile(r.data);
        });
    }, []);

    const formatUri = (hash: string) => `https://www.gravatar.com/avatar/${hash}?s=80`;

    return (
        profile ? <img
            className="w-8 h-8 rounded-full border-2 border-primary-700"
            src={formatUri(profile.gravatarHash)}
            alt="User profile"
            title={profile.fullName}
        /> : (
            <span className="flex items-center justify-center w-8 h-8 bg-primary-600 rounded-full">
                <UserIcon className="w-6 h-6 text-white -mt-1 shadow-lg" />
            </span>
        )
    );
};

export { ProfilePicture };

import React, { Dispatch, SetStateAction } from "react";

interface Props {
    pageNumber: number,
    totalPages: number,
    navigatePageCallback: Dispatch<SetStateAction<number>>
}

const MobileTablePagination = (props: Props): JSX.Element => {
    const navigatePage = (pageIndex: number) => {
        if (pageIndex < 0 || pageIndex > props.totalPages) return;

        props.navigatePageCallback(pageIndex);
    };

    return (
        <div className="flex-1 flex justify-between sm:hidden mb-2">
            <button
                type="button"
                onClick={() => navigatePage(props.pageNumber - 1)}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
                Previous
            </button>
            <button
                type="button"
                onClick={() => navigatePage(props.pageNumber + 1)}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
                Next
            </button>
        </div>
    );
};

export { MobileTablePagination };

import React, { useEffect, useState } from "react";
import { FilterParams } from "../../../../@types/File";
import { useDebounce } from "../../../../Hooks/useDebounce";
import { ToggleButton, ToggleButtonGroupPosition } from "../../../../Components/ToggleButton";

interface Props {
  filterValues: FilterParams,
  onFavouritesToggle: (val: boolean) => void,
  onDownloadsToggle: (val: boolean) => void,
  onSearch: (val: string) => void,
}

const MobileFilters = (props: Props): JSX.Element => {
    const [searchTerm, setSearchTerm] = useState(props.filterValues.search);
    const debounceSearch = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (debounceSearch !== undefined) {
            props.onSearch(debounceSearch);
        }
    }, [debounceSearch]);

    return (
        <div className="flex flex-col">
            <div className="flex lg:justify-end">
                <ToggleButton className="flex-none h-full" pos={ToggleButtonGroupPosition.Left} text="Favourites" onChange={props.onFavouritesToggle} checked={props.filterValues.favourites} />
                <ToggleButton className="flex-none h-full" pos={ToggleButtonGroupPosition.Right} text="My downloads" onChange={props.onDownloadsToggle} checked={props.filterValues.downloads} />
            </div>
            <div className="flex items-center">
                <div className="relative flex flex-grow text-gray-400 focus-within:text-gray-600">
                    <label htmlFor="search_field" className="sr-only">
                        Search files
                    </label>
                    <input
                        id="search_field"
                        className="flex-grow h-full py-3 pl-4 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="Search files"
                        type="search"
                        name="search"
                        onChange={(e) => { setSearchTerm(e.target.value); }}
                    />
                </div>
            </div>
        </div>
    );
};

export { MobileFilters };

import React from "react";

interface Props {
    text: string,
    icon: (props: React.ComponentProps<"svg">) => JSX.Element
}

const SideNavHeader = (props: Props): JSX.Element => (
    <h3 className="px-3 text-xs font-semibold tracking-wider uppercase text-primary-100">
        {props.text}
        {props.icon({ className: "inline-block w-4 h-4 ml-1 -mt-1 text-secondary-500" })}
    </h3>
);

export { SideNavHeader };

import React from "react";
import { NavLink } from "react-router-dom";
import { NavItem } from "../../../../@types/NavItem";

interface Props {
    item: NavItem,
    isDisabled?: Boolean
}

const SideNavLink = (props: Props): JSX.Element => (
    <NavLink
        exact
        key={props.item.name}
        to={props.item.href}
        activeClassName="bg-primary-800 text-white"
        className="text-primary-100 hover:bg-primary-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        onClick={e => {
            if (props.isDisabled) e.preventDefault();
        }}
    >
        <props.item.icon className="flex-shrink-0 w-6 h-6 mr-3 text-primary-300" aria-hidden="true" />
        <span className="truncate">
            {props.item.name}
        </span>
    </NavLink>
);

export { SideNavLink };

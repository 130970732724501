import { SearchIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { FilterParams } from "../../../../@types/CompanyUser";
import { useDebounce } from "../../../../Hooks/useDebounce";

interface Props {
  filterValues: FilterParams,
  onSearch: (val: string) => void,
}

const DesktopFilters = (props: Props): JSX.Element => {
    const [searchTerm, setSearchTerm] = useState(props.filterValues.search);
    const debounceSearch = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (debounceSearch !== undefined) {
            props.onSearch(debounceSearch);
        }
    }, [debounceSearch]);

    return (
        <>
            <div className="bg-white px-6 py-3 flex">
                <div className="relative flex flex-grow text-gray-400 focus-within:text-gray-600 pr-4">
                    <label htmlFor="search_field" className="sr-only">
                        Search files
                    </label>
                    <div className="absolute inset-y-0 left-0 flex flex-none items-center pointer-events-none">
                        <SearchIcon className="w-5 h-5" aria-hidden="true" />
                    </div>
                    <input
                        id="search_field"
                        className="h-full py-2 pl-8 pr-3 flex-grow text-gray-900 border-transparent placeholder-gray-500  focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="Search users"
                        type="search"
                        name="search"
                        value={searchTerm}
                        onChange={(e) => { setSearchTerm(e.target.value); }}
                    />
                </div>
            </div>
        </>
    );
};

export { DesktopFilters };

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAPI } from "../../Hooks/useAPI";
import { Result } from "../../@types/Result";
import { Faq, FaqResult } from "../../@types/Faq";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, MailOpenIcon, PaperAirplaneIcon, PhoneIcon, SearchIcon } from "@heroicons/react/outline";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { Dictionary } from "../../@types/Dictionary";
import { useDebounce } from "../../Hooks/useDebounce";
import { PageHeader } from "../../Components/Core/PageHeader";
import { FileDownload } from "../../Components/File/FileDownload";
import { AitemFileMinimal } from "../../@types/File";

const FAQsAndHelpPage = () => {
    const { get } = useAPI();
    const [featured, setFeatured] = useState<Array<Faq>>([]);
    const [categorised, setCategorised] = useState<Dictionary<Array<Faq>>>({});
    const [currentFaqs, setCurrentFaqs] = useState<Array<Faq>>([]);
    const [currentCategory, setCurrentCategory] = useState<string>("Featured FAQs");
    const [howToGuide, setHowToGuide] = useState<AitemFileMinimal>({ id: 0, name: "How-To-Guide.pdf", fileType: "pdf", fileSizeBytes: 0 });

    const [searchValue, setSearchValue] = useState<string>("");
    const debounceSearch = useDebounce(searchValue, 500);

    const changeCategory = (category: string) => {
        setCurrentFaqs(category === "Featured FAQs" ? featured : categorised[category]);
        setCurrentCategory(category);

        if (category !== "Search results") {
            setSearchValue("");
        }
    };

    const getUserRows = () => {
        const queryString = searchValue ? `?searchText=${searchValue}` : "";
        get<Result<FaqResult>>(`faqs${queryString}`).then(res => {
            setFeatured(res.data.featuredFaqs);
            setCategorised(res.data.categorisedFaqs);

            changeCategory(queryString ? "Search results" : "Featured FAQs");

            setCurrentFaqs(queryString ? res.data.searchResults : res.data.featuredFaqs);
        });
    };

    const getHowToGuideData = () => {
        get<Result<AitemFileMinimal>>(`files/${process.env.REACT_APP_HOW_TO_GUIDE_ID}/info`).then(guide => {
            setHowToGuide(guide.data);
        });
    };

    useEffect(() => {
        getHowToGuideData();
    }, []);

    useEffect(() => {
        if (debounceSearch != null) {
            getUserRows();
        }
    }, [debounceSearch]);

    const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ");

    return (
        <>
            <PageHeader header="Hi, let us know how we can help." />
            <div className="mt-4 bg-white sm:rounded-lg">
                <div className="mx-auto max-w-7xl">
                    <div className="w-full px-6 py-3 border-b border-gray-200">
                        <div className="relative flex flex-grow pr-4 text-gray-400 focus-within:text-gray-600">
                            <label htmlFor="search_field" className="sr-only">
                                Search FAQs
                            </label>
                            <div className="absolute inset-y-0 left-0 flex items-center flex-none pointer-events-none">
                                <SearchIcon className="w-5 h-5" aria-hidden="true" />
                            </div>
                            <input
                                id="search_field"
                                className="flex-grow h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                                placeholder="Search FAQs"
                                type="search"
                                name="search"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="px-4 py-12 lg:grid lg:grid-cols-3 lg:gap-8 sm:px-6 lg:py-16 lg:px-8">
                        <div className="space-y-8">
                            <h2 className="text-3xl font-extrabold text-gray-900">Frequently asked questions</h2>
                            <p>Everything you need to know so you can use our portal like a pro</p>
                            <ul className="mt-4 space-y-6 text-lg text-gray-500">
                                <li
                                    role="none"
                                    key="Search results"
                                    className={classNames(currentCategory === "Search results" ? "bg-secondary-50 ring ring-offset-2 ring-offset-white ring-secondary-500" : "hidden", "py-2 px-4 bg-gray-100 hover:bg-secondary-50 rounded-md cursor-pointer")}
                                    onClick={() => changeCategory("Search results")}
                                >
                                    Search results
                                </li>
                                <li
                                    role="none"
                                    key="Featured FAQs"
                                    className={classNames(currentCategory === "Featured FAQs" ? "bg-secondary-50 ring ring-offset-2 ring-offset-white ring-secondary-500" : "", "py-2 px-4 bg-gray-100 hover:bg-secondary-50 rounded-md cursor-pointer")}
                                    onClick={() => changeCategory("Featured FAQs")}
                                >
                                    Featured FAQs
                                </li>
                                {Object.keys(categorised).map(category => (
                                    <li
                                        role="none"
                                        key={category}
                                        className={classNames(currentCategory === category ? "bg-secondary-50 ring ring-offset-2 ring-offset-white ring-secondary-500" : "", "py-2 px-4 bg-gray-100 hover:bg-secondary-50 rounded-md cursor-pointer")}
                                        onClick={() => changeCategory(category)}
                                    >
                                        {category}
                                    </li>))}
                            </ul>
                            <div>
                                <p>Need to make a support request?  We&apos;ll help you in getting the most out of our portal.</p>
                                <dl className="grid grid-cols-1 mt-5 space-y-2 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg">
                                    <div className="flex flex-col items-center justify-center flex-1 py-4 space-y-2">
                                        <div>
                                            <MailOpenIcon className="inline-block w-6 h-6 -mt-1" /><span className="pl-3">Download our How-To Guide</span>
                                        </div>
                                        <FileDownload
                                            documentId={howToGuide.id}
                                            fileName={howToGuide.name}
                                            size={howToGuide.fileSizeBytes}
                                            format={howToGuide.fileType}
                                            linkClassName="w-full"
                                        >
                                            <Link
                                                to={`/aitem-files/${howToGuide.id}`}
                                                className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm disabled:opacity-50 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                onClick={e => e.preventDefault()}
                                            >
                                                Download Guide
                                            </Link>
                                        </FileDownload>
                                    </div>
                                    <div className="flex flex-col items-center justify-center flex-1 py-4 space-y-2">
                                        <div>
                                            <MailOpenIcon className="inline-block w-6 h-6 -mt-1" /><span className="pl-3">Send us an email</span>
                                        </div>
                                        <a href="mailto:support@aitemology.com" className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm disabled:opacity-50 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                                            support@aitemology.com
                                        </a>
                                    </div>
                                    <div className="flex flex-col items-center justify-center flex-1 py-4 space-y-2">
                                        <div>
                                            <PhoneIcon className="inline-block w-6 h-6 -mt-1" /><span className="pl-3">Give us a call</span>
                                        </div>
                                        <a href="tel:+44843000000" className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm disabled:opacity-50 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                                            0843 000 000
                                        </a>
                                    </div>
                                    <div className="flex flex-col items-center justify-center flex-1 py-4 space-y-2">
                                        <div>
                                            <PaperAirplaneIcon className="inline-block w-6 h-6 -mt-1" /><span className="pl-3">Any feedback?</span>
                                        </div>
                                        <a href="mailto:portal@aitemology.com" className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm disabled:opacity-50 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                                            portal@aitemology.com
                                        </a>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        <div className="mt-12 lg:mt-0 lg:col-span-2">
                            <dl className="h-full space-y-6 first:-mt-4">
                                {currentFaqs && currentFaqs.map((faq) => (
                                    <Disclosure as="div" key={faq.question}>
                                        {({ open }) => (
                                            <div className={classNames(open ? "bg-secondary-50 ring ring-secondary-500 ring-offset-2 ring-offset-white" : "", "p-4 rounded-lg")}>
                                                <dt className="text-lg">
                                                    <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-400">
                                                        <span className="font-medium text-gray-900">{faq.question}</span>
                                                        <span className="flex items-center ml-6 h-7">
                                                            <ChevronDownIcon className={classNames(open ? "-rotate-180" : "rotate-0", "h-6 w-6 transform")} aria-hidden="true" />
                                                        </span>
                                                    </Disclosure.Button>
                                                </dt>
                                                <Disclosure.Panel as="dd" className="pr-12 mt-2 text-base text-gray-500">
                                                    <ReactMarkdown
                                                        className="prose"
                                                        remarkPlugins={[gfm]}
                                                        components={{ a: ({ node, ...props }) => <Link to={props.href as string}>{props.children}</Link> }}
                                                    >
                                                        {faq.answer}
                                                    </ReactMarkdown>
                                                </Disclosure.Panel>
                                            </div>
                                        )}
                                    </Disclosure>
                                ))}
                                {currentCategory === "Search results" && currentFaqs && currentFaqs.length === 0
                                    ? (
                                        <div className="flex flex-col items-center justify-center h-full p-4 rounded-lg">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-36 h-36" viewBox="0 0 800 800">
                                                <path d="M411.146 142.174h-174.51a15.018 15.018 0 00-15 15v387.85l-2 .61-42.81 13.11a8.007 8.007 0 01-9.99-5.31l-127.34-415.95a8.003 8.003 0 015.31-9.99l65.97-20.2 191.25-58.54 65.97-20.2a7.99 7.99 0 019.99 5.3l32.55 106.32z" fill="#f2f2f2" />
                                                <path d="M449.226 140.174l-39.23-128.14a16.994 16.994 0 00-21.23-11.28l-92.75 28.39-191.24 58.55-92.75 28.4a17.015 17.015 0 00-11.28 21.23l134.08 437.93a17.027 17.027 0 0016.26 12.03 16.79 16.79 0 004.97-.75l63.58-19.46 2-.62v-2.09l-2 .61-64.17 19.65a15.015 15.015 0 01-18.73-9.95L2.666 136.734a14.98 14.98 0 019.95-18.73l92.75-28.4 191.24-58.54 92.75-28.4a15.156 15.156 0 014.41-.66 15.015 15.015 0 0114.32 10.61l39.05 127.56.62 2h2.08z" fill="#3f3d56" />
                                                <path d="M122.68 127.82a9.016 9.016 0 01-8.61-6.366l-12.88-42.072a8.999 8.999 0 015.97-11.24L283.1 14.278a9.009 9.009 0 0111.24 5.971l12.88 42.072a9.01 9.01 0 01-5.97 11.241l-175.94 53.864a8.976 8.976 0 01-2.63.395z" fill="#004E72" /><circle cx="190.154" cy="24.955" r="20" fill="#004E72" /><circle cx="190.154" cy="24.955" r="12.665" fill="#fff" />
                                                <path d="M602.636 582.174h-338a8.51 8.51 0 01-8.5-8.5v-405a8.51 8.51 0 018.5-8.5h338a8.51 8.51 0 018.5 8.5v405a8.51 8.51 0 01-8.5 8.5z" fill="#e6e6e6" />
                                                <path d="M447.136 140.174h-210.5a17.024 17.024 0 00-17 17v407.8l2-.61v-407.19a15.018 15.018 0 0115-15h211.12zm183.5 0h-394a17.024 17.024 0 00-17 17v458a17.024 17.024 0 0017 17h394a17.024 17.024 0 0017-17v-458a17.024 17.024 0 00-17-17zm15 475a15.018 15.018 0 01-15 15h-394a15.018 15.018 0 01-15-15v-458a15.018 15.018 0 0115-15h394a15.018 15.018 0 0115 15z" fill="#3f3d56" />
                                                <path d="M525.636 184.174h-184a9.01 9.01 0 01-9-9v-44a9.01 9.01 0 019-9h184a9.01 9.01 0 019 9v44a9.01 9.01 0 01-9 9z" fill="#004E72" />
                                                <circle cx="433.636" cy="105.174" r="20" fill="#004E72" />
                                                <circle cx="433.636" cy="105.174" r="12.182" fill="#fff" />
                                            </svg>
                                            <p className="text-gray-700">
                                                No results found.
                                            </p>
                                        </div>)
                                    : <></>}
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { FAQsAndHelpPage };

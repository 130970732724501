import React, { useEffect, useState } from "react";

import { AitemFile, FilterParams } from "../../@types/File";
import { PaginatedResult } from "../../@types/Result";
import { useHistory } from "react-router";
import { useAPI } from "../../Hooks/useAPI";
import { FilesTable } from "./Components/FilesTable";
import { SortParams } from "../../@types/SortParams";
import { useQuery } from "../../Hooks/useQuery";
import { PageHeader } from "../../Components/Core/PageHeader";

const AitemFilesPage = () => {
    const [rows, setRows] = useState<any>({});

    const query = useQuery();
    const downloadsFlag = query.get("downloads") === "true";
    const favouritesFlag = query.get("favourites") === "true";

    const [filterValues, setFilterValues] = useState<FilterParams>({ favourites: favouritesFlag,
        downloads: downloadsFlag,
        search: query.get("search") ?? undefined,
        folderId: parseInt(query.get("folderId") ?? "", 10) });

    const [sortValue, setSortValue] = useState<SortParams>({ isDescending: false, sortColumn: "Name" });
    const [pageIndex, setPageIndexValue] = useState(parseInt(query.get("page") ?? "1", 10));
    const [loading, setLoading] = useState(true);

    const { post } = useAPI();
    const { replace } = useHistory();

    useEffect(() => {
        if (favouritesFlag !== filterValues.favourites) {
            setFilterValues(curr => ({
                ...curr,
                favourites: favouritesFlag,
            }));
        }
    }, [favouritesFlag]);

    useEffect(() => {
        if (downloadsFlag !== filterValues.downloads) {
            setFilterValues(curr => ({
                ...curr,
                downloads: downloadsFlag,
            }));
        }
    }, [downloadsFlag]);

    useEffect(() => {
        const paginationRequest: any = {
            filters: {
                isFavourite: { value: filterValues.favourites },
                isUserDownload: { value: filterValues.downloads },
                fileName: { value: filterValues.search },
                folderId: { value: filterValues.folderId },
            },
            paginationParameters: {
                pageNumber: pageIndex,
                OrderByProperty: sortValue.sortColumn,
                IsDescending: sortValue.isDescending,
            },
        };

        post<PaginatedResult<AitemFile>>("files", paginationRequest).then(resultRows => {
            setRows(resultRows);
            setLoading(false);
        });

        const params = new URLSearchParams();

        // TODO: swap all filterValues to a map
        if (filterValues?.favourites) {
            params.append("favourites", filterValues.favourites.toString());
        }

        if (filterValues?.downloads) {
            params.append("downloads", filterValues.downloads.toString());
        }

        if (filterValues?.search) {
            params.append("search", filterValues.search.toString());
        }

        if (filterValues?.folderId) {
            params.append("folderId", filterValues.folderId.toString());
        }

        if (sortValue?.sortColumn) {
            params.append("sortBy", sortValue.sortColumn);
        }

        if (sortValue?.isDescending) {
            params.append("sortDesc", sortValue.isDescending ? "true" : "false");
        }

        if (pageIndex) {
            params.append("page", pageIndex.toString());
        }

        replace({ search: params.toString() });
    }, [filterValues, pageIndex, sortValue]);

    const setFavourites = (value: boolean) => {
        setFilterValues(curr => ({
            ...curr,
            favourites: value,
        }));
        setPageIndexValue(1);
    };

    const setDownloads = (value: boolean) => {
        setFilterValues(curr => ({
            ...curr,
            downloads: value,
        }));
        setPageIndexValue(1);
    };

    const setFolder = (value: React.ChangeEvent<HTMLSelectElement>) => {
        setFilterValues(curr => ({
            ...curr,
            folderId: parseInt(value.target.value, 10),
        }));
        setPageIndexValue(1);
    };

    const onSearch = (value: string) => {
        setFilterValues(curr => ({
            ...curr,
            search: value,
        }));
        setPageIndexValue(1);
    };

    return (
        <>
            <PageHeader header="Aitem files" />
            <div className="flex flex-col mt-4">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <FilesTable
                            loading={loading}
                            rows={rows}
                            activeSortColumn={sortValue.sortColumn}
                            activeSortDirection={sortValue.isDescending}
                            filterValues={filterValues}
                            onDownloadsToggle={setDownloads}
                            onFavouritesToggle={setFavourites}
                            navigatePageCallback={setPageIndexValue}
                            sortCallback={setSortValue}
                            onFolderChange={setFolder}
                            onSearch={onSearch}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export { AitemFilesPage };

import { format } from "bytes";
import moment from "moment";

const calendarDate = (date?: Date): string => moment(date).subtract(1, "month").format("DD/MM/yyyy");

const formatBytes = (bytes: number): string => format(bytes, { decimalPlaces: 0 });

const fullBytes = (bytes: number): string => `${bytes.toLocaleString(undefined)} bytes`;

const humaniseDate = (date?: Date): string => {
    if (!date) return "";

    const yearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

    return date < yearAgo
        ? calendarDate(date)
        : moment(date).subtract(1, "month").fromNow();
};

export { humaniseDate, calendarDate, formatBytes, fullBytes };

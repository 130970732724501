import React, { Dispatch, SetStateAction } from "react";

import { UserProfile } from "./UserProfile";
import { SearchBar } from "./SearchBar";
import { Breadcrumb } from "./Breadcrumb";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import { NotificationsMenu } from "./NotificationsMenu";

interface Props {
    toggleSidebar: Dispatch<SetStateAction<boolean>>
}

const MobileSidebarToggle = (toggleCallback: Dispatch<SetStateAction<boolean>>) => (
    <button
        type="button"
        className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
        onClick={() => toggleCallback(true)}
    >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
    </button>);

const TopNav = (props: Props): JSX.Element => (
    <>
        <div className="relative z-10 flex flex-shrink-0 h-16 bg-white shadow">
            {MobileSidebarToggle(props.toggleSidebar)}
            <div className="flex justify-between flex-1 px-4">
                <SearchBar />
                <div className="flex items-center ml-4 md:ml-6">
                    <NotificationsMenu />
                    <UserProfile />
                </div>
            </div>
        </div>
        <Breadcrumb />
    </>
);

export { TopNav };

import React, { useState } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { LoadingSpinner } from "../../../../Components/Core/LoadingSpinner";
import { useAPI } from "../../../../Hooks/useAPI";
import { classNames } from "../../../../Utils/Helpers";
import { ConfirmationModal } from "../../../../Components/Core/ConfirmationModal";
import { UnitResult } from "../../../../@types/Result";
import { toast } from "react-toastify";
import { CheckIcon } from "@heroicons/react/solid";

interface Props {
    userId: number,
    email: string,
    className?: string,
}

const ReSendInvite = (props: Props) => {
    const { post } = useAPI();
    const [loading, setLoading] = useState(false);
    const [waitingOnConfirmation, setWaitingOnConfirmation] = useState(false);
    const [inviteSent, setInviteSent] = useState(false);

    const click = () => {
        setLoading(true);
        setWaitingOnConfirmation(true);
    };

    const resendInvite = () => {
        post<UnitResult>("user/invite/resend", {
            userId: props.userId,
        })
            .then(r => {
                setLoading(false);
                if (r.successful) {
                    toast.success("Invite sent successfully.");
                    setInviteSent(true);
                } else {
                    toast.error("Something went wrong.");
                }
            })
            .catch(() => setLoading(false));
    };

    const confirm = () => {
        setWaitingOnConfirmation(false);
        resendInvite();
    };

    const cancel = () => {
        setLoading(false);
        setWaitingOnConfirmation(false);
    };

    return (
        inviteSent
            ? (
                <p>
                    <CheckIcon className="w-6 h-6 inline mr-2 text-green-600" />
                    <span className="align-middle">Invite sent.</span>
                </p>)
            : (
                <button
                    type="button"
                    title="User is not registered. Click to resend invite."
                    className={classNames(props.className ?? "flex-1")}
                    onClick={click}
                >
                    { loading
                        ? <LoadingSpinner className="inline-block w-6 h-6 m-2" />
                        : (
                            <button
                                type="button"
                                title="User is not registered. Click to resend invite."
                                className="inline-flex justify-center px-3 py-1 text-base font-medium text-white border border-transparent rounded-md shadow-sm disabled:opacity-50 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:col-start-2 sm:text-sm"
                            >
                                Resend invite
                            </button>
                        )}
                    <ConfirmationModal
                        header="Resend user invite?"
                        body={`Please confirm you wish to resend a user invite to ${props.email}.`}
                        continueText="Resend Invite"
                        closeText="Cancel"
                        icon={QuestionMarkCircleIcon}
                        open={waitingOnConfirmation}
                        confirmCallback={confirm}
                        cancelCallback={cancel}
                    />
                </button>
            )
    );
};

export { ReSendInvite };

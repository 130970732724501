import React, { ReactNode, useState, useRef } from "react";
import { DownloadConfirmationModal } from "../DownloadConfirmationModal";
import { useAPI } from "../../../Hooks/useAPI";
import { useBus } from "react-bus";

interface Props {
    documentId: number,
    fileName: string,
    format: string,
    size: number,
    linkClassName?: string,
    children?: ReactNode
}

const FileDownload = (props: Props): JSX.Element => {
    const { download } = useAPI();
    const bus = useBus();
    const downloadButtonRef = useRef(null);
    const [waitingOnConfirmation, setWaitingOnConfirmation] = useState(false);

    const requestDownload = (e:any) => {
        setWaitingOnConfirmation(true);
        e.preventDefault();
    };

    const downloadFile = () => {
        download(`files/${props.documentId}`)
            .finally(() => bus.emit("fileDownloaded"));
    };

    const downloadConfirmed = () => {
        setWaitingOnConfirmation(false);
        downloadFile();
    };

    const cancelDownload = () => {
        setWaitingOnConfirmation(false);
    };

    return (
        <a
            role="button"
            onClick={requestDownload}
            onKeyDown={requestDownload}
            ref={downloadButtonRef}
            tabIndex={0}
            className={props.linkClassName ?? ""}
        >
            {props.children}
            <DownloadConfirmationModal
                fileName={props.fileName}
                format={props.format}
                size={props.size}
                open={waitingOnConfirmation}
                confirmCallback={downloadConfirmed}
                cancelCallback={cancelDownload}
            />
        </a>
    );
};

export { FileDownload };

import React, { useContext, useState } from "react";
import { TopNav } from "./TopNav";
import { MobileSideNav } from "./SideNav/MobileSideNav";
import { DesktopSideNav } from "./SideNav/DesktopSideNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../Context/AuthProvider";
import { useIdleTimer } from "react-idle-timer";

interface Props {
  children: JSX.Element
}

const Layout = (props: Props): JSX.Element => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const authContext = useContext(AuthContext);

    const handleOnIdle = () => {
        toast.error("Your session has expired due to inactivity. Please login again.");
        setTimeout(() => {
            authContext?.logout();
        }, 5000);
    };

    useIdleTimer({
        timeout: (process.env.REACT_APP_IDLE_TIMEOUT_MS ?? Number.MAX_SAFE_INTEGER) as number,
        onIdle: handleOnIdle,
        debounce: 500,
        crossTab: {
            emitOnAllTabs: true,
        },
    });

    return (
        <div className="flex h-screen overflow-hidden bg-gray-100">
            <MobileSideNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <DesktopSideNav />
            <div className="flex flex-col flex-1 w-0 overflow-hidden">
                <TopNav toggleSidebar={setSidebarOpen} />
                <main className="relative flex-1 overflow-y-auto overflow-y-styled md:overflow-y-always focus:outline-none">
                    <div className="mx-auto max-w-7xl sm:px-6 md:px-8">
                        {props.children}
                    </div>
                </main>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                pauseOnHover
                limit={8}
            />
        </div>
    );
};

export { Layout };

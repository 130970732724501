/* eslint-disable max-len */
import React, { Dispatch, SetStateAction } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { classNames } from "../../../Utils/Helpers";

interface Props {
    totalItemsCount: number,
    itemsOnPage: number,
    pageNumber: number,
    itemsPerPage: number,
    totalPages: number,
    navigatePageCallback: Dispatch<SetStateAction<number>>
}

const range = (start: number, end: number): number[] => {
    if (Number.isNaN(start) || Number.isNaN(end) || start === end) return [];
    return [...Array(end).keys()].map(i => i + 1).filter(i => i > start - 1);
};

const findPagesToDisplay = (pageNumber: number, totalPages: number): number[] => {
    const lower = Math.max(0, pageNumber - 2);
    const upper = Math.min(totalPages, pageNumber + 2);
    return range(lower, upper);
};

const DesktopTablePagination = (props: Props): JSX.Element => {
    const itemIndexStart = ((props.pageNumber - 1) * props.itemsPerPage) + 1;
    const itemIndexEnd = itemIndexStart + props.itemsOnPage - 1;
    const pagesToDisplay = findPagesToDisplay(props.pageNumber, props.totalPages);

    const navigatePage = (pageIndex: number) => {
        props.navigatePageCallback(pageIndex);
    };

    const selectPage = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => navigatePage(changeEvent.target.value as unknown as number);

    return (
        <div className="bg-white px-6 py-3 flex w-full items-center justify-between border shadow-sm border-t-0 sm:rounded-b-lg">
            <div className="flex-1 flex items-center justify-between">
                <div>
                    {itemIndexStart && itemIndexEnd
                        ? (
                            <p className="text-sm text-gray-700">
                                Showing <span className="font-bold">{itemIndexStart}</span> to <span className="font-bold">{itemIndexEnd}</span> of{" "}
                                <span className="font-bold">{props.totalItemsCount}</span> results
                            </p>
                        )
                        : <></>}
                </div>
                <div>
                    <nav className="relative z-0 inline-flex items-center rounded-md -space-x-px" aria-label="Pagination">
                        <label className="mr-1 align-middle text-sm" htmlFor="page-number">
                            Page:
                        </label>
                        <select
                            name="page-number"
                            aria-label="page number"
                            value={props.pageNumber}
                            onChange={selectPage}
                            className="pl-3 pr-10 py-2 text-base border-none focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm"
                            defaultValue="1"
                        >
                            {/* eslint-disable-next-line react/no-array-index-key */}
                            {Array(props.totalPages).fill(0).map((_, i) => (<option key={i} value={i + 1}>{i + 1}</option>))}
                        </select>
                        <button
                            type="button"
                            onClick={() => navigatePage(props.pageNumber - 1)}
                            disabled={props.pageNumber <= 1}
                            className="disabled:opacity-50 relative cursor-pointer inline-flex items-center px-2 py-2 rounded-l-md border border-r-0 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        {pagesToDisplay.map((i: number) => (
                            <button
                                type="button"
                                onClick={() => navigatePage(i)}
                                key={i}
                                aria-current="page"
                                className={classNames(props.pageNumber === i ? "bg-indigo-50 border-primary-500 text-primary-600 z-20" : "",
                                    "z-10 relative inline-flex items-center px-4 py-2 border text-sm font-medium w-12 rounded-none")}
                            >
                                {i}
                            </button>
                        ))}
                        <button
                            type="button"
                            onClick={() => navigatePage(props.pageNumber + 1)}
                            disabled={props.pageNumber === props.totalPages}
                            className="disabled:opacity-50 relative inline-flex items-center px-2 py-2 rounded-r-md border border-l-0 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export { DesktopTablePagination };
